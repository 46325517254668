import React from "react";
import s from "../../styles/Dashboard.module.css";
import img from "../../img/Captura_de_pantalla_2024-02-01_a_las_10.05.14-removebg-preview.png"
import {getDataAccount, getUsers} from "../../redux/actions"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const Dashboard = () => {
  const cuenta = useSelector(state=>state.account)
  const users = useSelector(state=>state.allUsers)
  const obtenerFechaEnEspanol = () => {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    const fecha = new Date();
    const dia = fecha.getDate();
    const mes = fecha.getMonth();
    const año = fecha.getFullYear();

    const fechaEnEspanol = `${dia} de ${meses[mes]} de ${año}`;
    return fechaEnEspanol;
  };
 const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDataAccount())
    dispatch(getUsers())
  
  }, []);

 const sumarBalance = () => {
    let total_balance = 0;

    for (const cuentaItem of cuenta) {
      total_balance += cuentaItem?.balance?.amount;
    }

    return total_balance;
  };


  return (
    <div className={` ${s.containerDashboard}`}>
      <p>{obtenerFechaEnEspanol()}</p>
      <hr />
      <div className={s.welcome}>
        <p>Bienvenido a la Consola de Administración de Servicios Urbanos</p>
        <img className={s.img} src={img} alt="imagen" />
      </div>
      <div className={s.containerCards}>
        <div className={`card ${s.card}`}>
          <div className="card-body">
            <p>{`Usuarios:`} <b> {users?.length} </b></p>
           </div>
        </div>
        <div className={s.cardSeparator}></div>
        <div className={`card ${s.card}`}>
          <div className="card-body">
            <p>Saldo Disponible de la Cuenta Recaudadora <b>AR$ {sumarBalance()} </b></p>
            <p>Balance en Cuentas Virtuales </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
