// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
:root{
  --naranja: #063c62;
   font-size: 16px;
   font-family: "Rubik", sans-serif;
}
label{
  margin-right:5px ;
  margin-left:20px;
  font-size:14px
}

/* global */
input, select {
  height: 37px;

  font-size: 13px !important;
}
.w-100 .baseTab:hover{
  color:rgb(234, 238, 241) !important;
  background-color: rgb(5, 68, 117);
}
.w-100 .activeTab{
  color:rgb(247, 249, 250) !important;
  background-color: #063c62;
  
}
.dottedHr {
  
  border-style: dotted;
  border-color: #757373; 
  border-width: 1px;
  margin-left: 5px ; 
}
select option {
  font-size: 12px; /* Tamaño de fuente específico para las opciones (puedes ajustar según sea necesario) */
}
td, th {
  font-size: 13px !important;
}
b{
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;GACjB,eAAe;GACf,gCAAgC;AACnC;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB;AACF;;AAEA,WAAW;AACX;EACE,YAAY;;EAEZ,0BAA0B;AAC5B;AACA;EACE,mCAAmC;EACnC,iCAAiC;AACnC;AACA;EACE,mCAAmC;EACnC,yBAAyB;;AAE3B;AACA;;EAEE,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,eAAe,EAAE,uFAAuF;AAC1G;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,eAAe;AACjB","sourcesContent":["\n:root{\n  --naranja: #063c62;\n   font-size: 16px;\n   font-family: \"Rubik\", sans-serif;\n}\nlabel{\n  margin-right:5px ;\n  margin-left:20px;\n  font-size:14px\n}\n\n/* global */\ninput, select {\n  height: 37px;\n\n  font-size: 13px !important;\n}\n.w-100 .baseTab:hover{\n  color:rgb(234, 238, 241) !important;\n  background-color: rgb(5, 68, 117);\n}\n.w-100 .activeTab{\n  color:rgb(247, 249, 250) !important;\n  background-color: #063c62;\n  \n}\n.dottedHr {\n  \n  border-style: dotted;\n  border-color: #757373; \n  border-width: 1px;\n  margin-left: 5px ; \n}\nselect option {\n  font-size: 12px; /* Tamaño de fuente específico para las opciones (puedes ajustar según sea necesario) */\n}\ntd, th {\n  font-size: 13px !important;\n}\nb{\n  font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
