import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Filter_trans_codigo,
  getTransacciones,
} from "../../redux/actions";
import s from "../../styles/Transacciones.module.css";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { DetalleBusquedaCvuAlias } from "./DetalleBusquedaCvuAlias";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

const Search_trans = ({ setPagina }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const fechaActual = new Date().toISOString().split('T')[0];
  const [type, setType] = useState("");
  const [textBtn, setTextBtn] = useState("Buscar");
  const [error, setError] = useState("");
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [trans, setTrans] = useState({
    tipo: "",
    estado: "",
    codigo: "",
    data: "",
  });

  const [fechas, setFechas] = useState({
    inicio: "",
    fin: "",
  });
  const body = {
    obp_status: trans.estado || "",
    obp_from_date: fechas.inicio || "",
    obp_to_date: fechas.fin || "",
    obp_limit: 200,
    obp_offset: 0,
    obp_origin: type,
  };
  useEffect(() => {}, [trans, type]);


  const handleChangeFechas = (e) => {
    const { name, value } = e.target;

    if (value <= fechaActual) {
      setFechas({
        ...fechas,
        [name]: value,
      });
    } else {
      setFechas({
        ...fechas,
        [name]: fechaActual,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTextBtn("Buscando..");
    setError(""); // Limpiar cualquier error previo
   // console.log(body)
    if (trans.codigo) {
      const response = await dispatch(Filter_trans_codigo(trans.codigo));
      
      if (response.length === 0) {
        setError("No se encontraron resultados para el código ingresado.");
      }
    } else if (fechas.inicio || fechas.fin || trans.estado || type) {
     
      
      const response = await dispatch(getTransacciones(body));
      //console.log(response)
      if (response?.length === 0) {
        setError("No se encontraron transacciones con los filtros seleccionados.");
      }
    } else {
      setError("Por favor, seleccione al menos un filtro.");
    }

    setTextBtn("Buscar");
    setPagina(1);
  };

  const manejarCambio = (evento) => {
    const valorSeleccionado = evento.target.value;
    setTrans({ ...trans, estado: valorSeleccionado });
   
  };
const searchWhitCode =()=>{
  
}
  return (
    <div>
      <form className={`row`} onSubmit={handleSubmit}>
        <div className="col-lg-3 col-md-6">
          <label htmlFor="" className={s.fechalabel}>
            Fecha de alta desde:
          </label>
          <input
            type="date"
            className="form-control"
            value={fechas.inicio}
            name="inicio"
            onChange={handleChangeFechas}
          />
        </div>
        <div className="col-lg-3 col-md-6">
          <label htmlFor="" className={s.fechalabel}>
            Fecha de alta hasta:
          </label>
          <input
            className="form-control"
            type="date"
            value={fechas.fin}
            name="fin"
            onChange={handleChangeFechas}
          />
        </div>
        <div className="col-lg-4 col-md-4">
          <label htmlFor="">Tipo de Transferencia:</label>
          <select className="form-select" onChange={(e) => setType(e.target.value)}>
            <option value="" >Seleccionar</option>
            <option value="TRANSFERENCIAS_ENVIADAS">Transferencias enviadas</option>
            <option value="TRANSFERENCIAS_RECIBIDAS">Transferencias recibidas</option>
          </select>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <label htmlFor="">Estado:</label>
            <select className="form-select" onChange={manejarCambio}>
              <option value="">Seleccionar</option>
              <option value="IN_PROGRESS">En proceso</option>
              <option value="COMPLETED">Completa</option>
              <option value="FAILED">Fallida</option>
              <option value="PENDING">Pendiente</option>
              <option value="UNKNOWN">Desconocido</option>
            </select>
          </div>
          <div className="col-lg-6 mt-4">
            <button
              disabled={textBtn === "Buscando.."}
              style={{
                border: "solid 1px var(--naranja)",
                fontSize: "14px",
                marginLeft: "10px",
              }}
              className="btn btn-outline btn-lg"
              type="submit"
            >
              {textBtn}
            </button>
          </div>
        </div>
        {/* <div className="col-lg-3 col-md-6">
          <label htmlFor="" className={""}>
           Codigo de transacción:
          </label>
          <input
            className="form-control"
            type="text"
            value={trans.codigo}
            name="codigo"
            onChange={handleSubmit}
          />
        </div> */}
        <hr className={s.dottedHr}></hr>
        {error && (
          <div className="alert alert-warning" role="alert">
            {error}
          </div>
        )}
        <div className="d-flex justify-content-start align-items-end">
          <Modal isOpen={modalVisible} toggle={closeModal}>
            <ModalHeader style={{ backgroundColor: "var(--naranja)", color: "white" }} toggle={closeModal}>
              Detalle del usuario
            </ModalHeader>
            <ModalBody>
              <DetalleBusquedaCvuAlias data={modalData} />
            </ModalBody>
          </Modal>
        </div>
      </form>
    </div>
  );
};

export default Search_trans;


