// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Login_container__0c7uz{
   
    display: flex;
    justify-content: center;
    align-items: center;
   
}
.Login_formLogin__2mdQL{
    margin-top: 10%;
    box-shadow: rgba(0, 0, 0, 0.216) 0px 0px 70px 4px;
    padding: 30px;
    width: 400px;
    border-radius: 10px;
}
.Login_formLogin__2mdQL button{
    background-color: var(--naranja);
    float: right;
}

.Login_ojito__m\\+c3B{
    position: absolute;
      top: 70%;
      right:10px;
      transform: translateY(-50%);
      cursor: pointer
}
.Login_container_logo__4R0Rl{
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/Login.module.css"],"names":[],"mappings":";AACA;;IAEI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;AAEvB;AACA;IACI,eAAe;IACf,iDAAiD;IACjD,aAAa;IACb,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,gCAAgC;IAChC,YAAY;AAChB;;AAEA;IACI,kBAAkB;MAChB,QAAQ;MACR,UAAU;MACV,2BAA2B;MAC3B;AACN;AACA;IACI,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":["\n.container{\n   \n    display: flex;\n    justify-content: center;\n    align-items: center;\n   \n}\n.formLogin{\n    margin-top: 10%;\n    box-shadow: rgba(0, 0, 0, 0.216) 0px 0px 70px 4px;\n    padding: 30px;\n    width: 400px;\n    border-radius: 10px;\n}\n.formLogin button{\n    background-color: var(--naranja);\n    float: right;\n}\n\n.ojito{\n    position: absolute;\n      top: 70%;\n      right:10px;\n      transform: translateY(-50%);\n      cursor: pointer\n}\n.container_logo{\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Login_container__0c7uz`,
	"formLogin": `Login_formLogin__2mdQL`,
	"ojito": `Login_ojito__m+c3B`,
	"container_logo": `Login_container_logo__4R0Rl`
};
export default ___CSS_LOADER_EXPORT___;
