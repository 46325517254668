import React from "react";
import bus from "../img/bus_roto.png";
const Error = () => {
  return (
    <section className="flex items-center mt-3 ">
      <div className="container ">
        <div className=" text-center" style={{marginTop:"5%"}}>
        <h3 style={{color:"red" , fontWeight:"bold"}}>Oops!</h3>
          <p style={{ fontSize: "20px" }}>Ocurrió un error, página no encontrada</p>
          <img src={bus} alt="" style={{ width: "30%" }} />
        </div>
      </div>
    </section>
  );
};

export default Error;
