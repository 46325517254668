import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterBussiness, filterShop } from "../../redux/actions";


const Navbar = ({ setPagina }) => {
  const [name, setName] = useState(""); // Estado inicializado
  const [estado, setEstado] = useState(""); // Estado inicializado
  const dispatch = useDispatch();

  useEffect(() => {
    //console.log(search)
  }, [name]);

  const buscar = async (e) => {
    e.preventDefault();

    try {
      if (name) {
        dispatch(filterShop(name));
        setPagina(1);
      }
      if(estado){
        dispatch(filterShop(estado));
        setPagina(1);
      }


    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <nav className="container-fluid">
      <form className={`row pt-2`} role="search" onSubmit={buscar}>
       
        <div className="col-md-6 col-lg-3">
          <label htmlFor="label">Nombre:</label>
          <input
            className={`form-control`}
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="col-md-6 col-lg-3">
          <label htmlFor="label">Estado:</label>
          <select
            className={`form-select`}
            value={estado}
            onChange={(e) => setEstado(e.target.value)}
          >
            <option value="" hidden>
              Seleccionar
            </option>
            <option value="todos">Todos</option>
            <option value="activos">Activos</option>
            <option value="inactivos">Inactivos</option>
          </select>
        </div>
        <div className="col-lg-3 d-flex justify-content-center">
          <button
            style={{
              border: "solid 1px var(--naranja)",
              margin: "20px",
              fontSize: "14px",
            }}
            className="btn btn-outline btn-lg"
            type="submit"
          >
            Buscar
          </button>
        </div>

      </form>
      <hr className={"dottedHr"}></hr>
    </nav>
  );
};

export default Navbar;