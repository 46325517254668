import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTransacciones, transaccion_detalle } from "../../redux/actions";
import { useEffect, useState } from "react";
import Search_trans from "./Search_trans";
import { Spiner } from "../Spiner";
import s from "../../styles/Transacciones.module.css";
import Paginado from "../Consola usuarios/Paginado";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import Detalle_Tranferencia from "./Detalle_Tranferencia";
import { IoReload } from "react-icons/io5";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import Modal from "react-modal";
const ListaTransacciones = () => {
  const transacciones = useSelector((state) => state.transacciones);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setloading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();
  // Paginado
  const [pagina, setPagina] = useState(1);
  const [verPorPagina, setVerPorPagina] = useState(10);
  const maximo = Math.ceil(transacciones?.length / verPorPagina);
  const [reload, setReload] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const body = {
    obp_status: "",
    obp_from_date: "",
    obp_to_date: "",
    obp_limit: 200,
    obp_offset: 0,
    obp_origin: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      //console.log(transacciones)

      try {
        // setloading(true);
        await dispatch(getTransacciones(body));
        // setTimeout(() => {
        //   setloading(false)
        //  },3000);
      } catch (error) {
        console.error("Error fetching users:", error);
        setloading(false);
      }
    };

    fetchData();
    setTimeout(() => {
      if (!transacciones?.length) {
        setShowNoDataMessage(true);
      }
    }, 3000);
  }, []);

  if (loading) {
    return <Spiner />;
  }

  function openModal(id) {
    setIsOpen(true);
    setSelectedId(id);
  }

  function closeModal() {
    setIsOpen(false);
    setSelectedId(null);
  }
  const recargarTrans = () => {
    dispatch(getTransacciones(body));
    setReload(true);
  };
  return (
    <div className="container">
      <div className="d-flex ">
        <IoReload
          title="Recargar"
          onClick={recargarTrans}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
        <h5 className="text-center ms-4">Transacciones BIND</h5>
      </div>
      <Search_trans setPagina={setPagina} />
      {transacciones?.length ? (
        <div>
          <table className={"table"}>
            <thead className={` `}>
              <tr>
                <th colSpan="2">Fecha</th>
                <th colSpan="2">CUIL/CUIT</th>
                <th colSpan="2">Código Transacción</th>
                <th colSpan="2">CVU/Cuenta Remitente</th>
                <th colSpan="2">Monto $</th>
                <th colSpan="2">Tipo de Transferencia</th>
                <th colSpan="2">Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {transacciones
                ?.slice(
                  (pagina - 1) * verPorPagina,
                  (pagina - 1) * verPorPagina + verPorPagina
                )
                ?.map((t, index) => (
                  <tr key={index}>
                    <td colSpan="2">
                      {t.start_date &&
                        moment
                          .utc(t.start_date)
                          .tz("America/Argentina/Buenos_Aires")
                          .format("DD-MM-YYYY")}
                    </td>
                    <td colSpan="2">{t.counterparty?.id}</td>
                    <td colSpan="2">{t?.id}</td>
                    <td colSpan="2">
                      {t.counterparty?.account_routing?.address || "-"}
                    </td>

                    <td colSpan="2">
                      {Array.isArray(t?.details)
                        ? t.charge?.value?.amount
                        : t.charge?.value?.amount +
                          " " +
                          t.charge?.value?.currency}
                    </td>
                    <td colSpan="2">{t?.details?.type?.toLowerCase()}</td>
                    <td colSpan="2">{t.status}</td>
                    <td>
                      <Link
                        onClick={() => openModal(t.id)}
                        style={{ textDecoration: "none", color: "#F57720" }}
                      >
                        Ver detalle
                      </Link>
                      <Modal
                        isOpen={modalIsOpen}
                        className={s.modal}
                        onRequestClose={closeModal}
                        overlayClassName={s.Overlay} //fondo atras
                        contentLabel="Example Modal"
                      >
                        <Detalle_Tranferencia
                          id={selectedId}
                          pagina={pagina}
                          setPagina={setPagina}
                          closeModal={closeModal}
                        />
                      </Modal>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Paginado data={maximo} pagina={pagina} setPagina={setPagina} />
        </div>
      ) : (
        showNoDataMessage && (
          <div className="alert alert-warning" role="alert">
            No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
          </div>
        )
      )}
    </div>
  );
};

export default ListaTransacciones;
