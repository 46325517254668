import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, search_User_detalle, search_user_facetec } from "../../redux/actions";
import styles from "../../styles/Users.module.css";
import { Link } from "react-router-dom";
import Paginado from "./Paginado";
import Search from "./Search";
import { BiSearch } from "react-icons/bi";
import { IoReload } from "react-icons/io5";
import { Spiner } from "../Spiner"; // Suponiendo que tienes un componente Spinner
import Modal from "react-modal";
import DetalleUsuarios from "./DetalleUsuario";
import Movimientos from "./Movimientos";
import { GrTransaction } from "react-icons/gr";
import { CgMoreO } from "react-icons/cg";
import { FaUserCheck } from "react-icons/fa6";
import { FaUserTimes } from "react-icons/fa";
export const Usuarios = () => {
  const usuarios = useSelector((state) => state.users);
  const totalPage = useSelector((state) => state.totalUser);

  // Estados
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [estado, setEstado] = useState("Activo"); // Estado inicial
  const [modalDetailIsOpen, setIsDetailOpen] = useState(false);
  const [selectedIdUser, setSelectedIdUser] = useState(null);
  const [modalMovIsOpen, setIsMovOpen] = useState(false);
  const [selectedIdMov, setSelectedIdMov] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
   // console.log(usuarios)
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getUsers(page, estado));
        
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, page, estado]); // Ahora también depende del estado seleccionado

  const handlePageChange = (newPage) => {
    setPage(newPage); // Cambiar la página
  };

  const handleReload = () => {
    setLoading(true);
    setShowNoDataMessage(false); // Ocultar mensaje de "no data" al recargar
    dispatch(getUsers(page, estado)).then(() => setLoading(false));
  };

  // Manejar el cambio de estado del select
  const handleEstadoChange = (e) => {
    setEstado(e.target.value); // Cambiar estado
    setPage(1); // Reiniciar la página a 1 cuando se cambie el estado
  };
  //*----modal detalle usuario-----
  const openModalUser = (id,dni) => {

    setSelectedIdUser(id);
    setIsDetailOpen(true);
    dispatch(search_User_detalle({ _id: id, page: 1 }));
    dispatch(search_user_facetec({dni:dni, hide_images:false}))
  };

  const closeModalUser = () => {
    setIsDetailOpen(false);
    setSelectedIdUser(null);
  };
  //*----modal movmientos usuario-----
  const openModalMov = (cuit) => {
    setSelectedIdMov(cuit);
    setIsMovOpen(true);
  };

  const closeModalMov = () => {
    setIsMovOpen(false);
    // console.log("cerrar modal")
    setSelectedIdMov(null);
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center p-1">
        <IoReload
          title="Recargar"
          onClick={handleReload}
          style={{
            fontSize: "25px",
            color: "orange",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        />
        <h5
          style={{ margin: "10px", marginLeft: "20px" }}
          className="text-center"
        >
          Admin. de Usuarios
        </h5>
      </div>

      <Search setPagina={setPage} />

      <div className="col-md-6 col-lg-2 mb-2">
        <label htmlFor="estado-select">Estado:</label>
        <select
          id="estado-select"
          className={`form-select`}
          value={estado}
          onChange={handleEstadoChange}
        >
          <option value="" hidden>
            Seleccionar
          </option>

          <option value="Activo">Activos</option>
          <option value="Inactivo">Inactivos</option>
          <option value="Baja">Dados de baja</option>
          <option value="Rechazado">Rechazados</option>
        </select>
      </div>

      {loading ? (
        <Spiner /> // Mostrar Spinner mientras está cargando
      ) : usuarios && usuarios.length ? (
        <div>
          <table className={`table `}>
            <thead className="table-info">
              <tr>
                <th>Usuario</th>
                <th>Nombre y Apellido</th>
                <th>DNI</th>
                <th>CVU</th>
                <th>Saldo $</th>
                <th>Estado</th>
                <th>Ver Movimientos</th>
                <th>Ver Detalle</th>
              </tr>
            </thead>
            <tbody>
              {usuarios.map((user, index) => (
                <tr key={index}>
                  <td>{user.user}</td>
                  <td>{`${user.name} ${user.lastName}`}</td>
                  <td>{user.dni}</td>
                  <td>{user.cvu || "Pendiente"}</td>
                  <td>{parseFloat(user.currentBalance?.toFixed(2))?.toLocaleString('es-ES', { minimumFractionDigits: 2 })}</td>
                  <td>{user.state === "Activo" ? <p>Activo </p> : <p>Inactivo</p> }</td>
                  <td>
                    <button
                      className="btn p-1"
                      onClick={() => openModalMov(user?.cuit)}
                    >
                      <GrTransaction size={22} color="salmon" />
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn p-1"
                      onClick={() => openModalUser(user?._id,user?.dni)}
                    >
                      <CgMoreO size={22} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginado
            data={totalPage}
            pagina={page}
            setPagina={handlePageChange}
          />
        </div>
      ) : (
         (
          <div className="alert alert-warning" role="alert">
            No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
          </div>
        )
      )}
      <Modal
        isOpen={modalMovIsOpen}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalMov}
        contentLabel="Movimientos"
      >
        <Movimientos
          cuit={selectedIdMov}
          type=""
          page={page}
          setPagina={setPage}
          closeModalMov={closeModalMov}
        />
      </Modal>

      {/* modal detalle usuario */}

      <Modal
        isOpen={modalDetailIsOpen}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={closeModalUser}
        contentLabel="Detalle Asociado"
      >
        <DetalleUsuarios
          id={selectedIdUser}
          pagina={page}
          setPagina={setPage}
          closeModalUser={closeModalUser}
        />
      </Modal>
    </div>
  );
};
