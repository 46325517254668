import React, { useState, useEffect } from 'react';
import { Create_tax, updateTax } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Switch from "react-switch";
import { IoArrowBackCircleOutline } from "react-icons/io5";

const Impuestos = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    name: "",
    balance: "",
    state: false
  });

  const location = useLocation();
  const { Modificar, info } = location.state || {};
  const [status, setStatus] = useState(false);
  const [type, setType] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (Modificar && info) {
      setInput({
        name: info.name,
        balance: info.balance,
        state: info.state
      });
      setType(info.type);
      setStatus(info.state);  // Cambia a 'state' para manejar el estado del impuesto
    }
  }, [Modificar, info]);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const add_Tax = async (e) => {
    e.preventDefault();
    const sendData = {
      name: input.name,
      balance: parseFloat(parseFloat(input.balance).toFixed(2)),
      type: type,
      state: status  // Envía el estado del impuesto
    };
    if (!input.name || !input.balance || !type) {
      Swal.fire({
        icon: "error",
        text: "Complete todos los campos"
      });
      return;
    }

    const response = await dispatch(Create_tax(sendData));
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        text: "Agregado con éxito!"
      });
      setTimeout(() => {
        navigate("/list/impuestos");
      }, 500);
      setInput({ name: "", balance: "", state: false });
      setType("");
    } else {
      Swal.fire({
        icon: "error",
        text: response?.msg || "Ocurrió un error al realizar esta operación"
      });
    }
  };

  const update = async (e) => {
    e.preventDefault();
    const dataUpdate = {
      name: input.name,
      balance: input.balance,
      state: status  // Asegúrate de enviar el estado actualizado
    };

    const response = await dispatch(updateTax(dataUpdate));

    if (response.status === 200) {
      Swal.fire({
        icon: "success",
        text: "Datos actualizados con éxito!"
      });
      setTimeout(() => {
        navigate("/list/impuestos");
      }, 300);
      setInput({ name: "", balance: "", state: false });
      setType("");
    } else {
      Swal.fire({
        icon: "error",
        text: response?.msg || "Ocurrió un error al realizar esta operación"
      });
    }
  };

  return (
    <div className="container">
      {Modificar && info && (
        <Link to={"/list/impuestos"}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
      )}
      <div className="card p-3 mr-4">
        <h5
          className="mb-5 text-center p-2"
          style={{ color: "white", backgroundColor: "var(--naranja" }}
        >
          {!Modificar && !info ? "Agregar cargos de servicio" : "Modificar cargos de servicio"}
        </h5>

        <form className="row g-3">
          <h6>Completar datos</h6>
          <div className="col-md-4">
            <label className="Form-label">Nombre</label>
            <input
              disabled={info}
              type="text"
              className="form-control"
              name="name"
              value={input.name}
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label className="Form-label">Tipo</label>
            <select className="form-control" onChange={(e) => setType(e.target.value)} value={type}>
              <option value="">Seleccionar</option>
              <option name='type' value="Tax">Impuesto</option>
              <option value="Commission">Comisión</option>
            </select>
          </div>
          <div className="col-md-4">
            <label className="Form-label">Cargo %</label>
            <input
              type="number"
              className="form-control"
              name="balance"
              value={input.balance}
              onChange={handleChangeForm}
            />
          </div>
          {Modificar && info && (
            <div className="d-flex justify-content-around">
              <p
                style={{
                  alignItems: "center",
                  cursor: "pointer",
                  marginRight: 20,
                }}
              >
                {status ? "Activo" : "Inactivo"}
              </p>
              <Switch
                onChange={(checked) => setStatus(checked)}
                checked={status}
              />
            </div>
          )}
          <div className="col-12">
            {Modificar && info ? (
              <button
                style={{
                  border: "solid 1px var(--naranja)",
                  margin: "20px",
                  fontSize: "14px",
                }}
                className="btn btn-outline"
                onClick={update}
              >
                Guardar cambios
              </button>
            ) : (
              <button
                onClick={add_Tax}
                style={{
                  border: "solid 1px var(--naranja)",
                  margin: "20px",
                  fontSize: "14px",
                }}
                className="btn btn-outline"
                type="submit"
              >
                Agregar
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Impuestos;
