// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_containerNav__MUR\\+R{
  
    display: flex ;
    justify-content:space-between;
    width: 100vh;
    padding: 20px;
  
    box-shadow: rgba(242, 97, 13, 0.366) 0px 3px 12px;
}
.Navbar_user__DTk7R{
    display: flex;
   
}
.Navbar_logo_user__u7oTr{
    display:  flex;
   align-items: end;
   
}
.Navbar_logo_user__u7oTr a{ width: 14rem; }

/* search */
.Navbar_containerSearch__kcOXi{
 display: flex;
 justify-content: space-around;

}
.Navbar_formularioSearch__81ALD{
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.Navbar_select__G5e49 {
    width: 200px;
   font-size: 14px !important;
}
.Navbar_input__WansC{
    height: 35px !important;
    width: 200px;
}
button:hover{
    background-color: var(--naranja) !important;

    color: white !important;
}
.Navbar_dottedHr__D14Ak {
  
    border-style: dotted;
    border-color: #888; 
    border-width: 1px;
    margin-left: 20px ; 
  }`, "",{"version":3,"sources":["webpack://./src/styles/Navbar.module.css"],"names":[],"mappings":"AAAA;;IAEI,cAAc;IACd,6BAA6B;IAC7B,YAAY;IACZ,aAAa;;IAEb,iDAAiD;AACrD;AACA;IACI,aAAa;;AAEjB;AACA;IACI,cAAc;GACf,gBAAgB;;AAEnB;AACA,4BAAc,YAAY,EAAE;;AAE5B,WAAW;AACX;CACC,aAAa;CACb,6BAA6B;;AAE9B;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;;AAEvB;AACA;IACI,YAAY;GACb,0BAA0B;AAC7B;AACA;IACI,uBAAuB;IACvB,YAAY;AAChB;AACA;IACI,2CAA2C;;IAE3C,uBAAuB;AAC3B;AACA;;IAEI,oBAAoB;IACpB,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".containerNav{\n  \n    display: flex ;\n    justify-content:space-between;\n    width: 100vh;\n    padding: 20px;\n  \n    box-shadow: rgba(242, 97, 13, 0.366) 0px 3px 12px;\n}\n.user{\n    display: flex;\n   \n}\n.logo_user{\n    display:  flex;\n   align-items: end;\n   \n}\n.logo_user a{ width: 14rem; }\n\n/* search */\n.containerSearch{\n display: flex;\n justify-content: space-around;\n\n}\n.formularioSearch{\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n\n}\n.select {\n    width: 200px;\n   font-size: 14px !important;\n}\n.input{\n    height: 35px !important;\n    width: 200px;\n}\nbutton:hover{\n    background-color: var(--naranja) !important;\n\n    color: white !important;\n}\n.dottedHr {\n  \n    border-style: dotted;\n    border-color: #888; \n    border-width: 1px;\n    margin-left: 20px ; \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerNav": `Navbar_containerNav__MUR+R`,
	"user": `Navbar_user__DTk7R`,
	"logo_user": `Navbar_logo_user__u7oTr`,
	"containerSearch": `Navbar_containerSearch__kcOXi`,
	"formularioSearch": `Navbar_formularioSearch__81ALD`,
	"select": `Navbar_select__G5e49`,
	"input": `Navbar_input__WansC`,
	"dottedHr": `Navbar_dottedHr__D14Ak`
};
export default ___CSS_LOADER_EXPORT___;
