// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registro_conteinerRegistro__-9A-W{
    display: flex;
    justify-content: center;
}
.registro_conteinerForm__PX0Tl{

    padding: 20px;
    width: 40%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 20px;

}
.registro_btnRegistro__-aTk2{
    background-color: var(--naranja);
    color: white;
}
.registro_ojito__8USEo{
    position: absolute;
      top: 70%;
      right:10px;
      transform: translateY(-50%);
      cursor: pointer
}`, "",{"version":3,"sources":["webpack://./src/styles/registro.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;;IAEI,aAAa;IACb,UAAU;IACV,2FAA2F;IAC3F,mBAAmB;;AAEvB;AACA;IACI,gCAAgC;IAChC,YAAY;AAChB;AACA;IACI,kBAAkB;MAChB,QAAQ;MACR,UAAU;MACV,2BAA2B;MAC3B;AACN","sourcesContent":[".conteinerRegistro{\n    display: flex;\n    justify-content: center;\n}\n.conteinerForm{\n\n    padding: 20px;\n    width: 40%;\n    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;\n    border-radius: 20px;\n\n}\n.btnRegistro{\n    background-color: var(--naranja);\n    color: white;\n}\n.ojito{\n    position: absolute;\n      top: 70%;\n      right:10px;\n      transform: translateY(-50%);\n      cursor: pointer\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conteinerRegistro": `registro_conteinerRegistro__-9A-W`,
	"conteinerForm": `registro_conteinerForm__PX0Tl`,
	"btnRegistro": `registro_btnRegistro__-aTk2`,
	"ojito": `registro_ojito__8USEo`
};
export default ___CSS_LOADER_EXPORT___;
