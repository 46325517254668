import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Paginado from "../Consola usuarios/Paginado";
import { Spiner } from "../Spiner";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { FaShop } from "react-icons/fa6";
import Swal from "sweetalert2";
import { IoReload } from "react-icons/io5";
import { asignCvu, asignCvuEnty, creditShop, getShopsList, updateShop } from "../../redux/actions";
import Switch from "react-switch";
import Navbar from "./NavSearchShop";
import { IoIosAdd } from "react-icons/io";
import { RiBankLine } from "react-icons/ri";
const Shops = () => {
  const list = useSelector((state) => state.shops);
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [verPorPagina, setVerPorPagina] = useState(10);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
   getShops()
   
   // console.log(selectedCuit)
   setTimeout(() => {
    if(!list?.length){
      setShowNoDataMessage(true)
    } 
  }, 2000);
  }, [dispatch]);
 const getShops =async()=>{
  setLoading(true)
   const res= await dispatch(getShopsList())
   res && setLoading(false)
  }
 
  const updateState = async (cuit, newState) => {
    const stateNew= newState? "Activo":"Inactivo"
    try {
      const data = { cuit, state: stateNew };
      const response = await dispatch(updateShop(data));
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: `Se ${newState ? "habilitó" : "inhabilitó"} a este comercio`,
        });
        await dispatch(getShopsList());
      }else{
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response?.msg,
        });
      }
    } catch (error) {
      console.error("Error updating administrator state:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado del administrador",
      });
    }
  };
  useEffect(() => {
    if (!list) {
      setLoading(true);
    }
  }, [list]);
  

  
  const maximo = Math.ceil(list?.length / verPorPagina);


  const asignarCvu =async(cuit,clientId)=>{
    //console.log(cuit)
    try {
      
      const response = await dispatch(asignCvuEnty(cuit, clientId))
    
      if(response?.status==200){
       Swal.fire({
         icon: "success",
         title: "Ok",
         text: "CVU asignado con éxito!",
       })
       setTimeout(() => {
         dispatch(getShopsList())
         setPagina(pagina)
       }, 300);
     } else {
       Swal.fire({
         icon: "error",
         title: "Error",
         text: response.msg || "Ups! Ocurrió un error",
       });
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.msg || "Ups! Ocurrió un error",
      });
    }
   }

   const getTaxpayerType = (type) => {
    switch(type) {
      case 0.02066:
        return "Monotributista";
      default:
        return "Responsable Inscripto";
    }
  };
  return (
    <div className="">
      <div className="container">
      
            <div>
              <div className="d-flex align-items-center">
                <IoReload
                  title="Recargar"
                  onClick={() => dispatch(getShopsList())}
                  style={{
                    fontSize: "25px",
                    color: "orange",
                    fontWeight: "bold",
                    cursor: "pointer",
                    marginLeft: 18,
                  }}
                />
                <h5 style={{ margin: "10px", marginLeft: "20px" }} className="text-center">
                  Admin. de Comercios
                </h5>
                <Link
                  to={"/add/comercio"}
                  style={{
                    textDecoration: "none",
                    color: "black",
                    marginLeft: "50%",
                  }}
                >
                  <FaShop
                    style={{
                      fontSize: "22px",
                      color: "orange",
                      marginRight: "3px",
                    }}
                  />
                  Registrar comercio
                </Link>
              </div>
            </div>
           
             
                <Navbar setPagina={setPagina} />
                {
              
             
                list?.length ? (
                  <div id="#admin">
                    <div className="row r">
                      <div className="d-flex">
                        <p className="fs-6">Total de comercios: {list?.length}</p>
                      </div>
                    </div>
                    <table className={`table`}>
                      <thead className="table-info">
                        <tr>
                          <th>Nombre del comercio</th>
                          <th>CUIT</th>
                          <th>Tipo</th>
                          <th>Correo</th>
                          <th>CVU</th>
                          <th>Dirección</th>
                          <th>Estado</th>
                          <th>Acciones</th>
                          <th></th>
                       
                        </tr>
                      </thead>
                      <tbody>
                        {list
                          .slice(
                            (pagina - 1) * verPorPagina,
                            (pagina - 1) * verPorPagina + verPorPagina
                          )
                          .map((adm) => (
                            <tr key={adm?._id} style={{ textAlign: "left" }}>
                              <td>{adm?.nameShop}</td>
                              <td>{adm?.cuit}</td>
                              <td>{getTaxpayerType(adm?.type)}</td>
                              <td>{adm?.email}</td>
                              <td>{adm?.cvu}</td>
                              <td>
                                {adm?.address}, {adm?.province}
                              </td>
                              <td>
                                <div className="d-flex justify-content-around">
                              
                                  <Switch
                              
                                    onChange={(checked) => updateState(adm.cuit, checked)}
                                    checked={adm.state==="Activo"}
                                  />
                                </div>
                              </td>
                           
                              {/* <td>
                                <Link style={{textDecoration:"none", color:"orange"}} to={"/list/comprobantes/"+ adm.cuit}>
                                  Ver Comprobantes
                                </Link>
                              </td> */}
                              {
                             adm.cvu === "Pendiente"?(
                               
                               <td style={{color:"#106eb4", cursor:"pointer"}} onClick={()=> asignarCvu(adm?.cuit, adm.clientId)}>
                                <RiBankLine fontSize={18}/> Asignar CVU
                              </td>
                             ): <td>{null}</td>
                              }
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <Paginado data={maximo} pagina={pagina} setPagina={setPagina} />
                  </div>
                ) : (
                  showNoDataMessage && (
                    <div className="alert alert-warning" role="alert">
                      No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
                    </div>
                  )
                )
              }
          
          </div>
    </div>
  );
};

export default Shops;
