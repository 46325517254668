import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BiSearch } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Spiner } from "../Spiner";
import Swal from "sweetalert2";
import { GiReceiveMoney } from "react-icons/gi";
import Modal from "react-modal";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  Retiro_dinero_empresa,
  add_cvu_bussines,
  exist_cvu_bussine,
search_bussine_detail
} from "../../redux/actions";
import img from "../../img/money-icon-vector.jpg";
import bank from  "../../img/5044888.png"
import { CiBank } from "react-icons/ci";
const BussinessDetail = (params) => {
  const bussine = useSelector((state) => state.detalle_bussiness);
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modaAddCvu, setModalAddCvu] = React.useState(false);

  const [text, setText] = useState("Aceptar");
  const [textCvu, setTextCvu] = useState("Agregar");
  const [textDisabled, setTextDisabled] = useState(false);
  const [textDisabledCvu, setTextDisabledCvu] = useState(false);
  const [bussineDest, setBussineDest] = useState([]);
  const [selectCVU, setSelectCVU] = useState("");

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    cuit: params.cuit,
    cvuDest: "",
    amount: "",
    concept: "VAR",
  });
  useEffect(() => {
   //console.log(bussine)
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [bussineDest, selectCVU]);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const onChangeForm = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    // console.log(form)
  };

  const RetiroDinero = async (e) => {
    e.preventDefault();
    if (!selectCVU) {
      Swal.fire({
        title: "Ingrese un cvu",
        icon: "warning",
      });
      return;
    }
    if (!form.amount) {
      Swal.fire({
        title: "Ingrese un monto",
        icon: "warning",
      });
      return;
    }
    setText("Cargando..");
    setTextDisabled(true);
    const exist = {
      cuit: params.cuit,
      cvuDest: selectCVU,
    };
    const res = await dispatch(exist_cvu_bussine(exist));
    console.log(res.msg)
    if (res.status === 200) {
      setBussineDest(res?.msg?.data?.owners);
      setText("Aceptar");
      setTextDisabled(false);

      // console.log(bussineDest[0].display_name)
      Swal.fire({
        title: "Confirmar",
        text: `Seguro que desea tranferir a ${
          res?.msg?.data?.bank || res?.msg?.data?.bank_routing?.address
        }?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "cancelar",
        confirmButtonText: "Si",
      }).then((result) => {
        if (result.isConfirmed) {
          confirmRetiro();
        }
      });
    } else {
      Swal.fire({
        title: res?.data?.msg || "Ocurrió un error",
        icon: "error",
      });
      setText("Aceptar");
      setTextDisabled(false);
    }
  };
  const confirmRetiro = async () => {
    setText("Cargando..");
    setTextDisabled(true);
    const body = {
      cuit: params?.cuit,
      cvuDest: selectCVU,
      amount: parseFloat(form?.amount),
      concept:"VAR" ,
    };
    const res = await dispatch(Retiro_dinero_empresa(body));
    //console.log(res)
    if (res?.status === 200) {
      Swal.fire({
        title: "Operación exitosa!",
        icon: "success",
      });
      setText("Aceptar");
      setTextDisabled(false);
      closeModal(false);
      setForm({
        cvuDest: "",
        amount: "",
      });
    } else {
      Swal.fire({
        title: res?.data?.msg || "Ocurró un error ",
        icon: "error",
      });
      setText("Aceptar");
      setTextDisabled(false);
    }
  };

  const addAccountCvu = async () => {
    setTextCvu("Cargando..")
    setTextDisabledCvu(true)
    if (!form.cvuDest) {
      Swal.fire({
        title: "Ingrese un cvu",
        icon: "warning",
      });
      return;
    }
    const exist = {
      cuit: params.cuit,
      cvuDest: form.cvuDest,
    };
    const res = await dispatch(exist_cvu_bussine(exist));
    //console.log(res.msg);
    if (res.status === 200) {
      const bodyAddCvu = {
        id: params.id,
        data:res?.msg?.data
      };
      setTextCvu("Agregar")
      setTextDisabledCvu(false)

      Swal.fire({
        title: "Confirmar",
        text: `Agregar cuenta  ${ res?.msg?.data?.bank || res?.msg?.data?.bank_routing?.address }?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "cancelar",
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const result = await dispatch(add_cvu_bussines(bodyAddCvu));
          if (result.status === 200) {
            Swal.fire({
              title: "Cuenta agregada correctamente!",
              icon: "success",
            });
            setModalAddCvu(false);
            setForm({ cvuDest: "" });
            await dispatch(search_bussine_detail({ _id: bussine._id }));

          //  await dispatch(getBussinesList())
          } else {
            Swal.fire({
              title: result.data?.msg || "Ocurrió un error",
              icon: "error",
            });
          }
        }
      });
    } else {
      Swal.fire({
        title: res.data?.msg || "Ocurrió un error",
        icon: "error",
      });
    }
    setTextCvu("Agregar")
    setTextDisabledCvu(false)

    //console.log(bodyAddCvu)
  };

  const closeModalCvu =()=>{
    setModalAddCvu(true)
    setForm({cvuDest:""})
  }
  return (
    <div className="container ">
      <div className="d-flex justify-content-between mb-3">
        <Link onClick={params.closeModaldetail}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
        {bussine && (
          <div className="d-flex">
            <button
              className=" me-3 btn btn-outline-primary d-flex align-items-center text-white" style={{backgroundColor:"var(--naranja)"}}
              onClick={openModal}
            >
              Retiro de Dinero  <GiReceiveMoney size={23} style={{ color: "#f1fcfb" }} />
            </button>
            <button
              onClick={closeModalCvu }
              className="btn btn-outline-primary d-flex align-items-center text-white"
              style={{backgroundColor:"var(--naranja)"}}
              
            >
              Añadir una cuenta  <CiBank size={23} style={{ color: "#f1fcfb" }} />{" "}
            </button>
            <Modal
              isOpen={modaAddCvu}
              overlayClassName={""}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className=" p-3">
                <IoCloseCircleOutline
                  size={28}
                  style={{ color: "red", cursor: "pointer", marginBottom: 10 }}
                  onClick={() => setModalAddCvu(false)}
                />
                   <div className="d-flex justify-content-center">
                 <img src={bank} alt="imagen" style={{ width: 90 }} />
                </div>
                <p className="text-center fs-5">
                 Agregar una nueva cuenta
                </p>
                <input
                  placeholder="Ingresar  CVU/CBU"
                  className="form-control"
                  value={form.cvuDest}
                  name="cvuDest"
                  onChange={(e) => onChangeForm(e)}
                />
                <button
                 disabled={textDisabledCvu}
                  className="btn btn-outline-primary mt-2"
                  onClick={addAccountCvu}
                >
                  {textCvu}
                </button>
              </div>
            </Modal>
          </div>
        )}
      </div>
      {!loading ? (
        <div className="card p-3">
          <h5
            className="mb-5 text-center p-2"
            style={{ color: "white", backgroundColor: "var(--naranja" }}
          >
            {" "}
            Detalle Empresa de Transporte
          </h5>
          <form className="row g-3">
            <h6>Datos de la Empresa de Transporte</h6>
            <div className="col-md-4">
              <label htmlFor="inputEmail4" className="Form-label">
                Razón social
              </label>
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                name="businessName"
                value={bussine?.businessName}
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword4" className="Form-label">
                CUIT
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                value={bussine?.cuit}
                name="cuit"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputAddress2" className="Form-label">
                Correo Electrónico
              </label>
              <input
                disabled
                value={bussine?.email}
                name="email"
                type="text"
                className="form-control"
                id="inputAddress2"
                placeholder=""
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword4" className="Form-label">
                CVU
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                value={bussine?.cvu ? bussine.cvu: "Pendiente"}
                name="cuit"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword4" className="Form-label">
                Alias
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                value={bussine?.label ? bussine.label: "Pendiente"}
                name="cuit"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputPassword4" className="Form-label">
                Balance $
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPassword4"
                value={parseFloat(bussine?.currentBalance ?.toFixed(2))?.toLocaleString('es-ES', { minimumFractionDigits: 2 }) + " " + bussine.currency}
              
                name="cuit"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputAddress" className="Form-label">
                Teléfono
              </label>
              <input
                disabled
                value={bussine?.businessPhoneNumber}
                name="businessPhoneNumber"
                type="number"
                min={0}
                className="form-control"
                id="inputAddress"
              />
            </div>
            <hr style={{ borderTop: "2px dotted #000" }} />
            <h6>Datos de ubicación</h6>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                Pais
              </label>
              <input
                type="text"
                name="country"
                className="form-control"
                value={bussine?.country}
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputState" className="Form-label">
                Provincia
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.province}
                name="department"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputZip" className="Form-label">
                Localidad
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.department}
                name="department"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputZip" className="Form-label">
                Dirección
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.address}
                name="address"
                disabled
              />
            </div>
            <div className="col-md-2">
              <label htmlFor="inputZip" className="Form-label">
                Código Postal
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.postalCode}
                name="postalCode"
                disabled
              />
            </div>
            <hr style={{ borderTop: "2px dotted #000" }} />{" "}
            <h6>Datos del Representante legal</h6>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                Nombres
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.name}
                name="name"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                Apellidos
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.lastName}
                name="lastName"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                DNI
              </label>
              <input
                type="text"
                className="form-control"
                value={bussine?.dni}
                name="dni"
                disabled
              />
            </div>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                Teléfono
              </label>
              <input
                type="number"
                min={0}
                className="form-control"
                value={bussine?.phoneNumber}
                name="phoneNumber"
                disabled
              />
            </div>
            <hr style={{ borderTop: "2px dotted #000" }} />{" "}
            <h6>Declaración jurada</h6>
            <div className="col-md-4">
              <label htmlFor="inputCity" className="Form-label">
                Rubro
              </label>
              <select
                className="form-control"
                name="category"
                value={bussine?.category}
                disabled
              >
                <option value="" disabled>
                  {" "}
                  Seleccionar rubro
                </option>
                <option value="supermercado">Supermercado</option>
                <option value="viajes_turismo">Viajes y Turismo</option>
                <option value="indumentaria">Indumentaria</option>
                <option value="restaurante">Restaurante</option>
                <option value="automovil">Automovil</option>
                <option value="hogar">Hogar</option>
                <option value="servicios">Servicios</option>
                <option value="otro">Otro</option>
              </select>
            </div>
            <label className="Form-check-label" htmlFor="gridCheck">
              Declara que los fondos y valores a aplicar provienen de
              actividades lícitas
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="a1"
                disabled
                checked={bussine?.licitActivity}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              ></label>
            </div>
            <label className="Form-check-label" htmlFor="gridCheck">
              Declaro ser Persona Expuesta Politicamente
            </label>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="a2"
                disabled
                checked={bussine?.politicallyExposed}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              ></label>
            </div>
            <div className="col-6">
              <input
                type="text"
                name="reason"
                className=" form-control"
                placeholder="Motivo"
                disabled
                value={bussine?.reason}
              />
            </div>
            <label className="Form-check-label" htmlFor="gridCheck">
              Declaro ser sujeto obligado ante UIF
            </label>
            <div className="form-check form-switch ">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                name="a3"
                disabled
                checked={bussine?.subjectUIF}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckDefault"
              ></label>
            </div>
            <div className="col-6">
              <input
                type="text"
                className=" form-control"
                placeholder="Motivo"
                name="reasonUIF"
                disabled
                value={bussine?.reasonUIF}
              />
            </div>
          </form>
          <Modal
            isOpen={modalIsOpen}
            overlayClassName={""}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <IoCloseCircleOutline
              size={28}
              style={{ color: "red", cursor: "pointer" }}
              onClick={closeModal}
            />
            <div className="d-flex justify-content-center">
              <img src={img} alt="imagen" style={{ width: 90 }} />
            </div>
            <h3 className="text-center">Retiro de dinero </h3>
            <div> </div>
            <form className="p-3">
              <label htmlFor="">Monto $</label>
              <input
                className="form-control"
                value={form.amount}
                name="amount"
                onChange={(e) => onChangeForm(e)}
              />
             
                <div>
                  <label htmlFor="">Cuentas Registradas</label>
                  <select
                    className="form-control"
                    onChange={(e) => setSelectCVU(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {bussine?.accounts?.map((el, i) => (
                      <option key={i} value={el.cvu}>
                        {el.name}
                      </option>
                    ))}
                  </select>
                </div>
            

              <button
                disabled={textDisabled}
                className="btn btn-outline-primary mt-3"
                onClick={(e) => RetiroDinero(e)}
              >
                {text}
              </button>
            </form>
          </Modal>
        </div>
      ) : (
        <Spiner />
      )}
    </div>
  );
};

const customStyles = {
  content: {
    top: "50%",
    width: "40%",
    left: "55%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
  },
};

export default BussinessDetail;
