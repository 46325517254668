import React from "react";
import { useState,useEffect ,useCallback} from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Create_shop, getMunicipios, getProvincias } from "../../redux/actions";
const Comercio = () => {
  const [activo, setActivo] = useState({
    pep: false,
    licito: false,
    uif: false,
  });
  const [form, setForm]=useState({
      user: "",
      email: "",
      nameShop: "",
      cuit: "", //number
      postalCode: "",
      address: "",
      typePerson:"",
      dateCreate:"",
      cuitPerson:"",
      namePerson:"",
      phoneNumberPerson:"",
      phoneNumber:"",
      country:"Argentina",
    
      pep:false,
      licito:false,
      uif:false,
  })
  const provincias = useSelector((state) => state.provincias);
  const municipios = useSelector((state) => state.municipios);
  const navigate= useNavigate()
  const dispatch= useDispatch()
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [selectedResp, setSelectedResp] = useState("");
  const [selectedIva, setSelectedIva] = useState(true);
  useEffect(() => {
    dispatch(getProvincias());
  }, [dispatch]); 

  const InputChangeProvince = useCallback((value) => {
    setSelectedProvince(value);
    dispatch(getMunicipios(value));
  }, [dispatch]);

  const handleChangeForm = useCallback((e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
 
  }, []);

  const registrarComercio = async (e) => {
    e.preventDefault();
    try {
      for (const key in form) {
        if (form.hasOwnProperty(key)) {
          // Omitir el campo 'dateCreate' en la validación
          if (key === "dateCreate" || key ==="typePerson") {
            continue;
          }
          if (form[key] === "") {
            Swal.fire({
              text: `Debe completar todos los campos`,
              icon: "error",
            });
            return;
          }
        }
      }
      const updatedForm = {
        ...form,
        cuit: parseInt(form.cuit),
        province: selectedProvince,
        city: selectedMunicipio,
        departament: selectedMunicipio,
        type: selectedResp,
        iva: selectedIva ==="si"? true :false,
        pep:activo.pep,
        licito:activo.licito,
        uif:activo.uif,
      };

      const response = await dispatch(Create_shop(updatedForm));
      console.log(updatedForm);
      if (response?.status === 200) {
        Swal.fire({
          text: `Comercio creado correctamente!`,
          icon: "success",
        });
        // Limpiar los datos del FormData
        const newFormData = Object.fromEntries(
          Object.entries(form).map(([key, value]) => [key, ""])
        );
        setForm(newFormData);
        setSelectedMunicipio("");
        setSelectedProvince("");
        setSelectedResp("")
        setTimeout(() => {
          navigate("/list/comercio");
        }, 300);
        return;
      } else {
        Swal.fire({
          text: response?.msg || "Ocurrió un error al realizar el registro",
          icon: "error",
        });
        return;
      }
    } catch (error) {
      Swal.fire({
        text: error?.msg || "Ocurrió un error al realizar el registro",
        icon: "error",
      });
      return;
    }
  };


  const handleChangeStatus = (event) => {
    const { name, checked } = event.target;
    setActivo((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // Actualizar el estado FormData con el valor del checkbox
  setForm((prevState) => ({
    ...prevState,
    licito: name === "licito" ? checked : prevState.licito,
    pep: name === "pep" ? checked : prevState.pep,
    uif: name === "uif" ? checked : prevState.uif,
  }));
  console.log(activo)
  };
  return (
    <div className=" container">
      <div className="card p-3 mr-2">
        <h5
          className="mb-5 text-center p-2"
          style={{ color: "white", backgroundColor: "var(--naranja" }}
        >
          {" "}
          Formulario de Registro de Comercio
        </h5>
        <form className="row g-3" onSubmit={(e)=>registrarComercio(e)}>
        
          <h6>Datos de Comercio</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              CUIT
            </label>
            <input
              type="number"
              className="form-control"
              value={form.cuit}
              name="cuit"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress2" className="Form-label">
             Nombre del Comercio
            </label>
            <input
              onChange={handleChangeForm}
              value={form.nameShop}
              name="nameShop"
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder=""
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress2" className="Form-label">
              Correo Electrónico
            </label>
            <input
              onChange={handleChangeForm}
              value={form.email}
              name="email"
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder=""
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputState" className="Form-label">
              Provincia
            </label>

            <select
              className="form-control"
              value={selectedProvince}
              name="province"
              onChange={(e) =>
                InputChangeProvince(e.target.value)
              }
            >
              <option hidden></option>,
              {provincias?.map((provincia, index) => (
                <option key={index} value={provincia.nombre}>
                  {provincia.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Ciudad
            </label>
            <select
              className="form-control"
              value={selectedMunicipio}
              name="municipio"
              onChange={(e) =>
                setSelectedMunicipio(e.target.value)
              }
            >
              <option hidden></option>,
              {municipios?.map((mun, index) => (
                <option key={index} value={mun.nombre}>
                  {mun.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Telefóno
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              value={form.phoneNumber}
              name="phoneNumber"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Dirección
            </label>
            <input
              type="text"
              className="form-control"
              value={form.address}
              name="address"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputZip" className="Form-label">
              Código Postal
            </label>
            <input
              type="text"
              className="form-control"
              value={form.postalCode}
              name="postalCode"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
          <label htmlFor="inputZip" className="Form-label">
            Tipo de responsable
            </label>
          <select name="" id="" className="form-control" 
             onChange={(e) =>setSelectedResp(e.target.value)} value={selectedResp}>
          <option hidden value="">Seleccionar</option>
            <option value="0.02066">Monotributista</option>
            <option value="0.0250">Responsable Inscripto</option>
          </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputEmail4" className="Form-label">
              IVA
            </label>
          <select className="form-control"   value={selectedIva}
              name="iva"
              onChange={(e) =>
               setSelectedIva(e.target.value)
              }>
          <option value="">Seleccionar</option>
            <option value="si">Si</option>
            <option value="no">No</option>
          </select>
          </div>
         <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Datos del Representante legal</h6>
          <div className="col-md-4">
           <label htmlFor="inputCity" className="Form-label">
             Usuario
           </label>
           <input
             type="text"
             className="form-control"
             value={form.user}
             name="user"
             onChange={handleChangeForm}
           />
         </div>
         <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Nombres
            </label>
            <input
              type="text"
              className="form-control"
              value={form.namePerson}
              name="namePerson"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              CUIL
            </label>
            <input
              type="text"
              className="form-control"
              value={form.cuitPerson}
              name="cuitPerson"
              onChange={handleChangeForm}
            />
          </div>
         
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Telefóno
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              value={form.phoneNumberPerson}
              name="phoneNumberPerson"
              onChange={handleChangeForm}
            />
          </div>
     
        
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Declaración jurada</h6>
        
          <label className="Form-check-label" htmlFor="gridCheck">
            Declara que los fondos y valores a aplicar provienen de actividades
            lícitas
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="licito"
              onChange={handleChangeStatus}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.licito ? "No" : "Si"}
            </label>
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser Persona Expuesta Politicamente
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="pep"
              onChange={handleChangeStatus}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.pep ? "No" : "Si"}
            </label>
          </div>
         
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser sujeto obligado ante UIF
          </label>
          <div className="form-check form-switch ">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="uif"
              onChange={handleChangeStatus}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.uif ? "No" : "Si"}
            </label>
          </div>
        
       
          <div className="col-12">
            <button
              style={{
                border: "solid 1px var(--naranja",
                margin: "20px",
                fontSize: "14px",
              }}
              className="btn btn-outline "
              type="submit"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Comercio;
