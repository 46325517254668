// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dashboard_containerDashboard__r4GHN{
  margin-top: 20px;
   width: 1100px;
}
.Dashboard_containerCards__sbZwf{
   
    display: flex;
    justify-content:center;
    align-items: flex-end;

}
.Dashboard_card__LuexR{

    margin-right: 20px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.242) 0px 1px 4px;
}
.Dashboard_img__tCW4q{
    width: 35%;
  
    border-radius: 2%;
}
.Dashboard_welcome__3Dp3b{
    padding-top: 30px;
    display: flex;
    margin-bottom: 20px;
}
.Dashboard_welcome__3Dp3b p{
  
    font-size: 35px;
    font-weight: bold;
    word-spacing: 5px;
    color:rgba(13, 12, 13, 0.872);
    font-family:Arial, Helvetica, sans-serif
}

.Dashboard_card__LuexR div p{
    font-size: 22px;
    color: var(--naranja);
}

/* Media query para tablets y dispositivos medianos */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
   .Dashboard_containerDashboard__r4GHN{
    margin-top: 20px;
    width: 600px;
 
   }
   .Dashboard_welcome__3Dp3b{
    display: block;
    
   }
   .Dashboard_welcome__3Dp3b p{
    font-size: 25px;
   }
   .Dashboard_card__LuexR div p{
    font-size: 16px;
  
}
.Dashboard_containerCards__sbZwf{
    width: 650px;
}
  }`, "",{"version":3,"sources":["webpack://./src/styles/Dashboard.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;GACf,aAAa;AAChB;AACA;;IAEI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;;AAEzB;AACA;;IAEI,kBAAkB;IAClB,YAAY;IACZ,4CAA4C;AAChD;AACA;IACI,UAAU;;IAEV,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,aAAa;IACb,mBAAmB;AACvB;AACA;;IAEI,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IACjB,6BAA6B;IAC7B;AACJ;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA,qDAAqD;AACrD;GACG;IACC,gBAAgB;IAChB,YAAY;;GAEb;GACA;IACC,cAAc;;GAEf;GACA;IACC,eAAe;GAChB;GACA;IACC,eAAe;;AAEnB;AACA;IACI,YAAY;AAChB;EACE","sourcesContent":[".containerDashboard{\n  margin-top: 20px;\n   width: 1100px;\n}\n.containerCards{\n   \n    display: flex;\n    justify-content:center;\n    align-items: flex-end;\n\n}\n.card{\n\n    margin-right: 20px;\n    padding: 5px;\n    box-shadow: rgba(0, 0, 0, 0.242) 0px 1px 4px;\n}\n.img{\n    width: 35%;\n  \n    border-radius: 2%;\n}\n.welcome{\n    padding-top: 30px;\n    display: flex;\n    margin-bottom: 20px;\n}\n.welcome p{\n  \n    font-size: 35px;\n    font-weight: bold;\n    word-spacing: 5px;\n    color:rgba(13, 12, 13, 0.872);\n    font-family:Arial, Helvetica, sans-serif\n}\n\n.card div p{\n    font-size: 22px;\n    color: var(--naranja);\n}\n\n/* Media query para tablets y dispositivos medianos */\n@media only screen and (min-width: 768px) and (max-width: 1023px) {\n   .containerDashboard{\n    margin-top: 20px;\n    width: 600px;\n \n   }\n   .welcome{\n    display: block;\n    \n   }\n   .welcome p{\n    font-size: 25px;\n   }\n   .card div p{\n    font-size: 16px;\n  \n}\n.containerCards{\n    width: 650px;\n}\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerDashboard": `Dashboard_containerDashboard__r4GHN`,
	"containerCards": `Dashboard_containerCards__sbZwf`,
	"card": `Dashboard_card__LuexR`,
	"img": `Dashboard_img__tCW4q`,
	"welcome": `Dashboard_welcome__3Dp3b`
};
export default ___CSS_LOADER_EXPORT___;
