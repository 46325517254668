import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { BiSearch } from "react-icons/bi";
export const DetalleBusquedaCvuAlias = () => {
  const detail = useSelector((state) => state.detailCvuAlias);

 
useEffect(() => {
  //console.log("Detail en useEffect:", detail);
}, [detail]);


  return (
    <div>
    {
      detail?
        detail?.map((d, index) => (
          <div key={index} className="col p-2">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <b className="fs-6">Nombre: </b>
                {d?.owners && d.owners[0] && d.owners[0].display_name}
              </li>
              <li className="list-group-item">
                <b className="fs-6">Cuit: </b>
                {d?.owners && d.owners[0] && d.owners[0].id}
              </li>
              <li className="list-group-item">
                <b className="fs-6">CVU: </b>
                {d?.account_routing &&
                  d.account_routing &&
                  d.account_routing.address}
              </li>
              <li className="list-group-item">
                <b className="fs-6">Banco: </b>
                {d?.bank_routing && d.bank_routing && d.bank_routing.address}
              </li>
              <li className="list-group-item">
                <b className="fs-6">Alias: </b>
                {d?.label}
              </li>
              <li className="list-group-item">
                <b className="fs-6">Moneda: </b>
                {d?.currency}
              </li>
              <li className="list-group-item">
                <b className="fs-6">Estado: </b>
                {d?.is_active ? "activo" : "Inactivo"}
              </li>
            </ul>
          </div>
        )):
        <div className="d-flex justify-content-center mt-5">
                <p>
                  {" "}
                  No se encontraron datos
                  <BiSearch style={{ fontSize: "30px" }} />
                </p>
              </div>
              
    }
    </div>
  );
};
