// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baseTab{
  color:black !important;
    cursor: pointer;
}
.baseTab:hover{
 opacity: 0.7 !important;
 color: rgb(20, 19, 19) !important;
}
.activeTab{
    background-color: var(--naranja) !important;
    color: rgba(19, 17, 17, 0.905) !important ;
}
.nav-tabs .nav-item .nav-link {
width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/Tabs.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;IACpB,eAAe;AACnB;AACA;CACC,uBAAuB;CACvB,iCAAiC;AAClC;AACA;IACI,2CAA2C;IAC3C,0CAA0C;AAC9C;AACA;AACA,YAAY;AACZ","sourcesContent":[".baseTab{\n  color:black !important;\n    cursor: pointer;\n}\n.baseTab:hover{\n opacity: 0.7 !important;\n color: rgb(20, 19, 19) !important;\n}\n.activeTab{\n    background-color: var(--naranja) !important;\n    color: rgba(19, 17, 17, 0.905) !important ;\n}\n.nav-tabs .nav-item .nav-link {\nwidth: 20rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
