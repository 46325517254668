import { devToolsEnhancerLogOnly } from "@redux-devtools/extension";
import { TYPES } from "./actions.js";
const initialState = {
  users: [],
  user_detalle: [],
  allUsers: [],
  error: null,
  isAuthenticated: !!localStorage.getItem("user-log"),
  admin: [],
  all_Admin: [],
  all_Transacciones: [],
  transacciones: [],
  account: [],
  detailCvuAlias: [],
  movimientos: [],
  fotos: [],
  provincias: [],
  municipios: [],
  bussines: [],
  shops: [],
  all_shops: [],
  taxs: [],
  listTypes: [],
  transaccion_detail: [],
  all_bussiness: [],
  asociados: [],
  user: [],
  mov_user: [],
  all_asociados: [],
  totalUser:"",
  totalAsc:"",
  detalle_asociado:[],
  detalle_bussiness:[]
};

export function rootReducer(state = initialState, action) {
  switch (action.type) {
    //SESION
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case TYPES.LOG_OUT:
      window.localStorage.removeItem("user-log");
      return {
        ...state,
        user: [],
        isAuthenticated: false,
      };
    // ERROR
    case TYPES.ERROR_MENSAJE:
      return {
        error: action.payload,
      };
    // USERS
    case TYPES.GET_USERS:
  
      return {
        ...state,
        users: action.payload,
        allUsers: action.payload,
        totalUser:action.payload2,
        error: null,
      };

    // SEARCH
    case TYPES.SEARCH_USER:
  
      return {
        ...state,
      users:  action.payload,
      totalUser:action.payload2,
     
  }
    case TYPES.SEARCH_USER_DETALLE:
    
      return {
        ...state,
        user_detalle: action.payload,
      };
    //  SEARCH /FILTrOS
    case TYPES.SEARCH_USER_BY_VALIDATION:
     

      return {
        ...state,
        users: action.payload,
      };
    case TYPES.SEARCH_USER_BY_NAME:
    
    
      return {
        ...state,
        users: action.payload,
        totalUser:action.payload2,
      };
    // Admin
    case TYPES.GET_ADMIN:
      return {
        ...state,
        admin: action.payload,
        all_Admin: action.payload,
      };
    case TYPES.SEARCH_ADMIN_BY_NAME:
      return {
        ...state,
        admin: state.all_Admin.filter((adm) =>
          adm.name.toLowerCase().includes(action.payload.toLowerCase())
        ),
      };
    case TYPES.SEARCH_ADMIN_BY_DNI:
      return {
        ...state,
        admin: state.all_Admin.filter((adm) =>
          adm.dni.toString().includes(action.payload)
        ),
      };
    case TYPES.SEARCH_ADMIN_BY_STATE:
      let searchadmin;
     // console.log(action.payload);
      if (action.payload === "activo") {
        searchadmin = state.all_Admin.filter((a) => a.state === true);
      }
      if (action.payload === "todos") {
        searchadmin = state.all_Admin;
      }
      if (action.payload === "inactivo") {
        searchadmin = state.all_Admin.filter((a) => a.state === false);
      }
      return {
        ...state,
        admin: searchadmin,
      };
    // trans
    case TYPES.GET_TRANSACCIONES:
      return {
        ...state,
        all_Transacciones: action.payload,
        transacciones: action.payload,
      };

    case TYPES.FILTER_TRANS_BY_CODIGO:
      //console.log(state.all_Transacciones);
      return {
        ...state,
        transacciones: state.all_Transacciones.filter((el) =>
          el.id.includes(action.payload)
        ),
      };
    case TYPES.GET_TRANS_BY_CVU_LABEL:
      let data = [];
      data.push(action.payload);
      return {
        ...state,
        detailCvuAlias: data,
      };
    //account
    case TYPES.GET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    // movimientos
    case TYPES.GET_MOVIMIENTOS:
      return {
        ...state,
        movimientos: action.payload,
      };
    case TYPES.FOTOS:
      return {
        ...state,
        fotos: action.payload,
      };
    case TYPES.LIST_PROVINCES:
      return {
        ...state,
        provincias: action.payload,
      };
    case TYPES.LIST_MUNICIPIOS:
      return {
        ...state,
        municipios: action.payload,
      };
    case TYPES.LIST_BUSSINES:
      return {
        ...state,
        bussines: action.payload,
        all_bussiness: action.payload,
      };
    case TYPES.LIST_SHOPS:
      return {
        ...state,
        shops: action.payload,
        all_shops: action.payload,
      };
    case TYPES.LIST_TAXS:
      return {
        ...state,
        texs: action.payload,
      };

    case TYPES.LIST_TYPES:
      return {
        ...state,
        listTypes: action.payload,
      };
    case TYPES.FILTRO_TRANS:
      let filtroTrans = state.all_Transacciones?.filter((el) =>
        el.status.includes(action.payload)
      );

      return {
        ...state,
        transacciones: filtroTrans,
      };
    case TYPES.TRANSACCION_DETALLE:
     // console.log(action.payload)
      const resultTrans = state.all_Transacciones.filter(el=> el.id===action.payload)
     
      return {
        ...state,
        transaccion_detail: resultTrans
      };
      
    case TYPES.FILTER_BUSSINESS:
      //console.log(action.payload);
      let bussinessFil;

      if (action.payload === "activos") {
        bussinessFil = state.all_bussiness.filter((t) => t.state === true);
      } else if (action.payload === "inactivos") {
        bussinessFil = state.all_bussiness.filter((t) => t.state === false);
      } else if (action.payload === "todos") {
        bussinessFil = state.all_bussiness;
      } else {
        bussinessFil = state.all_bussiness.filter((t) =>
          t.businessName.toLowerCase().includes(action.payload.toLowerCase())
        );
      }

      return {
        ...state,
        bussines: bussinessFil,
      };

    case TYPES.FILTER_SHOP:
      //console.log(action.payload);
      let shopFil;

      if (action.payload === "activos") {
        shopFil = state.all_shops.filter((t) => t.state === "Activo");
      } else if (action.payload === "inactivos") {
        shopFil = state.all_shops.filter((t) => t.state === "Inactivo");
      } else if (action.payload === "todos") {
        shopFil = state.all_shops;
      } else {
        shopFil = state.all_shops.filter((t) =>
          t.nameShop.toLowerCase().includes(action.payload.toLowerCase())
        );
      }

      return {
        ...state,
        shops: shopFil,
      };
    case TYPES.LIST_ASOCIADOS:
      return {
        ...state,
        asociados: action.payload,
        all_asociados: action.payload,
        totalAsc:action.payload2
      };
    case TYPES.MOV_USER:
      return {
        ...state,
        mov_user: action.payload,
      };
    case TYPES.SEARCH_ASOCIADO_BY_DNI:
      return {
        ...state,
        asociados: action.payload,
        totalAsc:action.payload2
      };
    case TYPES.FILTER_ASOCIADO_BY_ESTADO:
      let searchAsociado;
     // console.log(state.all_asociados);
      if (action.payload === "si") {
        searchAsociado = state.all_asociados.filter((a) => a.state === true);
      }
      if (action.payload === "todos") {
        searchAsociado = state.all_asociados;
      }
      if (action.payload === "no") {
        searchAsociado = state.all_asociados.filter((a) => a.state === false);
      }
      return {
        ...state,
        asociados: searchAsociado,
      };
    case TYPES.SEARCH_ASOCIADO_BY_NAME:
 
      return {
        ...state,
        asociados: action.payload,
        totalAsc:action.payload2
         
      };
      case TYPES.LIST_ASOCIADOS:
        return {
          ...state,
          asociados: action.payload,
          all_asociados: action.payload,
        };
        case TYPES.CLEAR_MOV_USER:
      return {
        ...state,
        mov_user: [],
   
      };
      case TYPES.CLEAR_DETAIL:
        return {
          ...state,
          transaccion_detail: [],
     
        };
        case TYPES.ASOCIADO_DETAIL:
          return {
            ...state,
            detalle_asociado: action.payload,
       
          };
          case TYPES.BUSINESS_DETALLE:
           
             return {
              ...state,
              detalle_bussiness: action.payload,
         
            };
       
     

    default:
      return state;
  }
}
