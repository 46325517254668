import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { create_admin, create_user } from "../../redux/actions";
const Operador = () => {
  const [provincias, setProvincias] = useState([]);
  const [activo, setActivo] = useState({
    a1: false,
    a2: false,
    a3: false,
  });
  const [FormData, setFormData] = useState({
    cuil: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dni: "",
    province: "",
    department: "",
    city: null ||"-",
    county: "Argentina",
    address: "",
    postalCode: "",
    rol: "",
    agency: "",
  });
  useEffect(() => {
    const obtenerProvincias = async () => {
      try {
        const provinciasData = [
          "Buenos Aires",
          "CABA",
          "Catamarca",
          "Chaco",
          "Chubut",
          "Córdoba",
          "Corrientes",
          "Entre Ríos",
          "Formosa",
          "Jujuy",
          "La Pampa",
          "La Rioja",
          "Mendoza",
          "Misiones",
          "Neuquén",
          "Río Negro",
          "Salta",
          "San Juan",
          "San Luis",
          "Santa Cruz",
          "Santa Fe",
          "Santiago del Estero",
          "Tierra del Fuego",
          "Tucumán",
        ];
        setProvincias(provinciasData);
      } catch (error) {
        console.error("Error al obtener las provincias:", error);
      }
    };
    obtenerProvincias();

  }, []);
useEffect(() => {
 
 // console.log(FormData);
}, [FormData]);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value, 
    });
   // console.log(FormData);
  };
  const dispatch = useDispatch();
  const Registar_Operador = (e) => {
    e.preventDefault();
    try {
      for (const key in FormData) {
        if (FormData.hasOwnProperty(key) && FormData[key] === "") {
          Swal.fire({
            text: `Debe completar todos los campos`,
            icon: "error",
          });
          return; // Detener la ejecución si se encuentra un campo vacío
        }
      }
   
       dispatch(create_admin(FormData));
      Swal.fire({
        text: "Empresa de Operador creada con éxito",
        icon: "success",
      });
      //console.log(FormData)
    }
    catch(error){

    }
   
    }
  return (
    <div className="container p-1">
      <div className="card p-3 mr-4">
        <h5
          className="mb-5 text-center p-2"
          style={{ color: "white", backgroundColor: "#ff8c00" }}
        >
          {" "}
          Formulario de Registro de Operador
        </h5>
        <form className="row g-3" onSubmit={Registar_Operador}>
          <h6>Datos del Operador</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              CUIL
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.cuil}
              name="cuil"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              DNI
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.dni}
              name="dni"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Telefóno
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              value={FormData.phoneNumber}
              name="phoneNumber"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress2" className="Form-label">
              Correo Electrónico
            </label>
            <input
              onChange={handleChangeForm}
              value={FormData.email}
              name="email"
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder=""
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Nombres
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.name}
              name="name"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Apellidos
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.lastName}
              name="lastName"
              onChange={handleChangeForm}
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />
          <h6>Datos de ubicación</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Pais
            </label>
            <input
              type="text"
              name="country"
              className="form-control"
              value="Argentina"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputState" className="Form-label">
              Provincia
            </label>

            <select
              className="form-control"
              value={FormData.province}
              name="province"
              onChange={(e) =>
                setFormData({ ...FormData, province: e.target.value })
              }
            >
              <option hidden></option>,
              {provincias.map((provincia, index) => (
                <option key={index} value={provincia}>
                  {provincia}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Localidad
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.department}
              name="department"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Dirección
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.address}
              name="address"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputZip" className="Form-label">
              Código Postal
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.postalCode}
              name="postalCode"
              onChange={handleChangeForm}
            />
          </div>
       
        
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Seleccionar Rol y Agencia del Operador</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Agencia
            </label>
            <select
              className="form-control"
              name="category"
              value={FormData.category}
              onChange={handleChangeForm}
            >
              <option value="" disabled>
                {" "}
                Seleccionar agencia
              </option>
              <option value="">---</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Agencia
            </label>
            <select
              className="form-control"
              name="category"
              value={FormData.category}
              onChange={handleChangeForm}
            >
              <option value="" disabled>
                {" "}
                Rubro
              </option>
              <option value="">Administrador de agencia</option>
              <option value="">Operador</option>
            </select>
          </div>
          <div className="col-12">
            <button
              style={{
                border: "solid 1px var(--naranja",
                margin: "20px",
                fontSize: "14px",
              }}
              className="btn btn-outline "
              type="submit"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Operador;