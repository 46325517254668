import React from "react";

export const Spiner = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "50vh", width:"100vh", marginLeft:"15%" }}
    >
      <div className="spinner-border text-danger" role="status" >
        <span className="visually-hidden">Cargando...</span>
      </div>
    </div>
  );
};

