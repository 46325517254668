import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get_account_balance, get_moviments_accounts } from "../redux/actions";
import Paginado from "./Consola usuarios/Paginado";
import { Spiner } from "./Spiner";

const Conciliacion = () => {
  const accounts = useSelector((state) => state.account_balance);
  const movimentsList = useSelector((state) => state.account_mov);
  const total = useSelector((state) => state.total_mov_page);
  const dispatch = useDispatch();
  
  const [selectedType, setSelectedType] = useState("");
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("Para obtener datos seleccionar los filtros");
  const [btnText, setBtnText] = useState("Buscar");
  const [page, setPage] = useState(1);

  const [body, setBody] = useState({
    inicio: "",
    fin: "",
    page: 1,
    type: "",
  });

  useEffect(() => {
    getBlanceAccount();
  }, [dispatch]);

  // Actualizar el cuerpo de la solicitud al cambiar la página o los filtros
  useEffect(() => {
    const updatedBody = {
      inicio: fechaDesde,
      fin: fechaHasta,
      page: page,
      type: selectedType,
    };
    setBody(updatedBody);
  }, [page, selectedType, fechaDesde, fechaHasta]);

  // useEffect(() => {
  //   if (body.page) {
  //     fetchMovements();
  //   }
  // }, [body]);

  const getBlanceAccount = async () => {
    try {
      await dispatch(get_account_balance());
    } catch (error) {
      console.error("Error fetching movements:", error);
    }
  };

  const fetchMovements = async () => {
    try {
      setLoading(true);
      const res = await dispatch(get_moviments_accounts(body));
      if (!res?.length) {
        setMessage("Seleccionar filtros");
      }
    } catch (error) {
      console.error("Error fetching movements:", error);
    } finally {
      setLoading(false);
      setBtnText("Buscar");
    }
  };

  const handleTypeChange = (e) => {
    setSelectedType(e);
    setPage(1); // Reiniciar la página al cambiar el filtro
  };

  const handleDesdeChange = (e) => {
    setFechaDesde(e);
    setPage(1); // Reiniciar la página al cambiar el filtro
  };

  const handleHastaChange = (e) => {
    setFechaHasta(e);
    setPage(1); // Reiniciar la página al cambiar el filtro
  };

  const handlePageChange =async (newPage) => {
    setPage(newPage); // Cambiar la página
    await dispatch(get_moviments_accounts({...body, page:newPage}))
  };

  const btbSearch = async (e) => {
    e.preventDefault();
    setBtnText("Buscando...");
    setPage(1); // Reiniciar la página al buscar
    await fetchMovements(); // Ejecutar la búsqueda con el estado actualizado
  };

  return (
    <div className="container">
      {/* Mostrar cuentas */}
      <div className="col d-flex p-2 ">
        {Array.isArray(accounts?.bind) &&
          accounts?.bind?.map((comercio, index) => (
            <div key={index} className="card m-3 p-4">
              <h6
                style={{
                  backgroundColor: "var(--naranja)",
                  padding: 10,
                  color: "white",
                }}
              >
                Balance Cuenta General
              </h6>
              <p>
                Saldo Actual: $
                {comercio?.balance &&
                  String(
                    comercio?.balance?.amount?.toLocaleString("es-ES", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  )}
              </p>
            </div>
          ))}
        {accounts?.comercios && (
          <div className="card m-3 p-4">
            <h6
              style={{
                backgroundColor: "var(--naranja)",
                padding: 10,
                color: "white",
              }}
            >
              Balance Cuenta Comercio
            </h6>
            <p>
              Saldo Actual: $
              {accounts.comercios?.toLocaleString("es-ES", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        )}
      {accounts?.empresas && (
          <div className="card m-3 p-4">
            <h6
              style={{
                backgroundColor: "var(--naranja)",
                padding: 10,
                color: "white",
              }}
            >
              Balance Cuenta Empresa
            </h6>
            <p>
              Saldo Actual: $
              {accounts.comercios?.toLocaleString("es-ES", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        )}
         {accounts?.usuarios && (
          <div className="card m-3 p-4">
            <h6
              style={{
                backgroundColor: "var(--naranja)",
                padding: 10,
                color: "white",
              }}
            >
              Balance Cuenta Usuarios
            </h6>
            <p>
              Saldo Actual: $
              {accounts.comercios?.toLocaleString("es-ES", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </div>
        )}
      </div>

      <hr />
      {/* Busqueda */}
      <form action="" className="row mb-3">
        <div className="col-3">
          <select
            className=" form-control"
            onChange={(e) => handleTypeChange(e.target.value)}
          >
            <option hidden value="">
              Seleccionar tipo de movimiento
            </option>
            <option value="Transferencia Recibida">
              Transferencias recibidas
            </option>
            <option value="Transferencia Enviada">
              Transferencias enviadas
            </option>
            <option value="Compra de Saldo">Compra de Saldo</option>
            <option value="Pago de Pasaje: Check In">Check in</option>
            <option value="Devolución de Pasaje: Check Out">Check out</option>
          </select>
        </div>
        <div className="col-6">
          <div className="d-flex">
            <label>Fecha Desde:</label>
            <input
              onChange={(e) => handleDesdeChange(e.target.value)}
              type="date"
              className="form-control"
              max={new Date().toISOString().split("T")[0]}
            />
            <label>Fecha Hasta:</label>
            <input
              onChange={(e) => handleHastaChange(e.target.value)}
              type="date"
              className="form-control"
              max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <button
          className="col-1 btn btn-outline"
          style={{ border: "solid 1px var(--naranja)", fontSize: "14px" }}
          type="submit"
          onClick={(e) => btbSearch(e)}
        >
          {btnText}
        </button>
      </form>

      {movimentsList?.length ? (
        <div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Código Transacción</th>
                <th scope="col">Nombre Origen</th>
                <th scope="col">Nombre Destino</th>
                <th scope="col">Cvu Destino</th>
                <th scope="col">Tipo</th>
                <th scope="col">Monto $</th>
              </tr>
            </thead>
            <tbody>
              {movimentsList.map((el, i) => (
                <tr key={i}>
                  <td>{el?.codigo}</td>
                  <td>{`${el?.nombreOrig || "-"} ${el?.apellidoOrig || ""}`}</td>
                  <td>{`${el?.nombreDest || "-"} ${el?.apellidoDest || ""}`}</td>
                  <td>{el?.cvuDest || "-"}</td>
                  <td>{el?.tipo || "-"}</td>
                  <td>{el?.monto}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-warning" role="alert">
          {message}
        </div>
      )}

      <Paginado data={total} pagina={page} setPagina={handlePageChange} />
    </div>
  );
};

export default Conciliacion;

