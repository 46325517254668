import axios from 'axios';
var user = localStorage.getItem("user-log");
const token = JSON.parse(user)?.token; // guardo solo el token

const {REACT_APP_URL }= process.env

class AuthService {
  async login(data) {
  
  
    try {
      const response = await axios.post(REACT_APP_URL, data, {
      
      });
  // console.log(response)
     
      return response;
    } catch (error) {
     // console.log(error)
      // Capturar el error y manejarlo según tus necesidades
      if (error.response) {
        console.error('Error de respuesta del servidor:', error.response.data);
        return error.response; 
      } else if (error.request) {
        
      
        return { ok: false, status: 500, msg: 'No se recibió respuesta del servidor' };
      } else {
        
        console.error('Error durante la configuración de la solicitud:', error.message);
        return { ok: false, status: 500, msg: 'Error durante la configuración de la solicitud' };
      }
    }
  }

  register(data) {
    return axios.post(REACT_APP_URL, data);
  }
}

export default new AuthService();

