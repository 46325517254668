import React from 'react'
import { useState, useEffect} from 'react'
import { useSelector ,useDispatch} from 'react-redux'

const PerfilAdmin = () => {
    const dispatch = useDispatch()
    const [usuario,setUsuario]= useState({})

    const obtenerUserLog=()=>{

      const user = localStorage.getItem("user-log")
      const json= JSON.parse(user)
      setUsuario(json)
    }

    useEffect(() => {
     obtenerUserLog()
     //console.log(usuario.data_user)
    }, []);
  return (
    <div>
        <div className='card'>
           
        <h5>Datos del Perfil</h5>
        <p>{usuario?.data_user?.name}</p>
        <button>Cambiar contraseña</button>
        </div>
        
    </div>
  )
}

export default PerfilAdmin