import axios from "axios";
import { useIsRTL } from "react-bootstrap/esm/ThemeProvider";
const REACT_URL_BACK = process.env.REACT_APP_URL;
const REACT_APP_URLVAL = process.env.REACT_APP_URLVAL;
const REACT_SECRET = process.env.REACT_APP_SECRET;

export const TYPES = {
  ERROR_MENSAJE: "ERROR_MENSAJE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOG_OUT: "LOG_OUT",
  GET_USERS: "GET_USERS",
  SEARCH_USER: "SEARCH_USER",
  SEARCH_USER_DETALLE: "SEARCH_USER_DETALLE",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER",
  SEARCH_USER_BY_VALIDATION: "SEARCH_USER_BY_VALIDATION",
  SEARCH_USER_BY_NAME: "SEARCH_USER_BY_NAME",
  FILTER_BY_ESTADO: "FILTER_BY_ESTADO",
  GET_ADMIN: "GET_ADMIN",
  SEARCH_ADMIN_BY_NAME: "SEARCH_ADMIN_BY_NAME",
  SEARCH_ADMIN_BY_DNI: "SEARCH_ADMIN_BY_DNI",
  SEARCH_ADMIN_BY_STATE: "SEARCH_ADMIN_BY_STATE",
  GET_TRANSACCIONES: "GET_TRANSACCIONES",
  GET_TRANS_BY_CVU_LABEL: "GET_TRANS_BY_CVU_LABEL",
  FILTER_TRANS_BY_ESTADO: "FILTER_TRANS_BY_ESTADO",
  FILTER_TRANS_BY_CODIGO: "FILTER_TRANS_BY_CODIGO",
  GET_ACCOUNT: "GET_ACCOUNT",
  GET_MOVIMIENTOS: "GET_MOVIMIENTOS",
  CREATE_TRANSPORT: "CREATE_TRASPORT",
  CREATE_SHOP: "CREATE_SHOP",
  FOTOS: "FOTOS",
  LIST_BUSSINES: "LIST_BUSSINES",
  LIST_SHOPS: "LIST_SHOPS",
  LIST_TAXS: "LIST_TAXS",
  LIST_PROVINCES: "LIST_PROVINCES",
  LIST_MUNICIPIOS: "LIST_MUNICIPIOS",
  FILTRO_TRANSPORT: "FILTRO_TRANSPORT",
  FILTRO_TRANS: "FILTRO_TRANS",
  LIST_TYPES: "LIST_TYPES",
  LIST_ASOCIADOS: "LIST_ASOCIADOS",
  TRANSACCION_DETALLE: "TRANSACCION_DETALLE",
  FILTER_BUSSINESS: "FILTER_BUSSINESS",
  FILTER_SHOP: "FILTER_SHOP",
  MOV_USER: "MOV_USER",
  SEARCH_ASOCIADO_BY_NAME: "SEARCH_ASOCIADO_BY_NAME",
  SEARCH_ASOCIADO_BY_DNI: "SEARCH_ASOCIADO_BY_DNI",
  FILTER_ASOCIADO_BY_ESTADO: "FILTER_ASOCIADO_BY_ESTADO",
  CLEAR_MOV_USER: "CLEAR_MOV_USER",
  CLEAR_DETAIL: "CLEAR_DETAIL",
  ASOCIADO_DETAIL: "ASOCIADO_DETAIL",
  BUSINESS_DETALLE: "BUSINESS_DETALLE",
  MOVIMENTS_BUSSINE: "MOVIMENTS_BUSSINE",
  SHOP_DETAIL: "SHOP_DETAIL",
  CLAEAR_PHOTOS:"CLEAR_PHOTOS",
  DETAIL_CONCILIATION:"DETAIL_CONCILIATION",
  CLAEAR_DETAIL_CONCILIACION:"CLAEAR_DETAIL_CONCILIACION",
  ACCOUNT_BALANCE:"ACCOUNT_BALANCE",
  ACCOUNT_MOVIMENTS: "ACCOUNT_MOVIMENTS",

};
// Obtener el token almacenado en el local storage
var user = localStorage.getItem("user-log");
const token = JSON.parse(user)?.token; // guardo solo el token

const body = {
  obp_status: "",
  obp_from_date: "",
  obp_to_date: "",
  obp_limit: 200,
  obp_offset: 0,
  obp_origin: "",
};
export function LoginSuccess(data) {
  try {
    return async function (dispatch) {
      window.localStorage.setItem("user-log", JSON.stringify(data));

      if (data) {
        await dispatch(getUsers());
        await dispatch(getAsociados());
      
        await dispatch(getAdmin());
      }
      dispatch({
        type: TYPES.LOGIN_SUCCESS,
        payload: data,
      });
    };
  } catch (error) {
    //console.log(error.message);
  }
}
export function LogOut() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.LOG_OUT,
    });
  };
}

// Asegúrate de que la variable de entorno está correctamente definida

export function getUsers(page, estado) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    const body = {
      page: page,
      filter: estado,
    };
    // Imprimir el token inmediatamente después de obtenerlo
    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }

    try {
      // Imprimir el token antes de realizar la solicitud

      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/Users`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      dispatch({
        type: TYPES.GET_USERS,
        payload: response.data.data,
        payload2: response.data.total,
      });
    } catch (error) {
      //console.error("Error Response:", error.response);
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}

// buscar  DNI
export const search_User = (search) => {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/UserDNI`,
        { dni: search, page: 1 },
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      dispatch({
        type: TYPES.SEARCH_USER,
        payload: response.data?.data,
        payload2: response.data?.total,
      });

      return response.data;
    } catch (error) {}
  };
};

// buscar  DNI detalle
export const search_User_detalle = (_id) => {
  //console.log(cuit)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    //console.log(_id)
    const response = await axios.post(`${REACT_URL_BACK}v1/List/UserDNI`, _id, {
      headers: {
        "SERVICIOS-URBANOS-TOKEN": token,
      },
    });

    try {
      dispatch({
        type: TYPES.SEARCH_USER_DETALLE,
        payload: response.data,
      });
      //console.log(response.data)
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  };
};
// buscar por nombre

export function search_by_name(userSearch) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    //console.log(userSearch)
    const response = await axios.post(
      `${REACT_URL_BACK}v1/List/UserDNI`,
      userSearch,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
    //console.log(response.data.data)
    try {
      dispatch({
        type: TYPES.SEARCH_USER_BY_NAME,
        payload: response.data?.data,
        payload2: response.data?.total,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}

// CREAR USUARIO
export const create_user = (user) => {
  const login = localStorage.getItem("user-log");
  const token = JSON.parse(login)?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}Auth/addUser?user=${user.user}&pass=${user.password}&name=${user.name}&dni=${user.dni}`,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      dispatch({
        type: TYPES.CREATE_USER,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
};
//MODIFICAR
export const Update_user = (dni, datos) => {
  //console.log(datos)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const data = await axios.put(`${REACT_URL_BACK}v1/User/UpdUser`, datos, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });

      return data.data;
    } catch (error) {
      return error.response;
    }
  };
};
// adminISTRADORES
export function getAdmin() {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    // Imprimir el token inmediatamente después de obtenerlo

    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Admin`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data.data);

      dispatch({
        type: TYPES.GET_ADMIN,
        payload: response?.data?.data,
      });
      return response.data;
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
// filtros admin
export function search_Admin_by_name(data) {
  return async function (dispatch) {
    //e.log(data);
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_NAME,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
export function search_Admin_by_dni(data) {
  return async function (dispatch) {
    //console.log(data)
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_DNI,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
//  estado
export function search_admin_state(data) {
  return async function (dispatch) {
    //console.log(data);
    try {
      dispatch({
        type: TYPES.SEARCH_ADMIN_BY_STATE,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
export const create_admin = (data) => {
  // console.log(token)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async (dispatch) => {
    try {
      const response = await axios.post(`${REACT_URL_BACK}addAdmin`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log("create admin",response.data);
      dispatch({
        type: TYPES.CREATE_USER,
        payload: response.data.data,
      });
      return response.data;
    } catch (error) {
      //console.log("error", error);

      return error.response.data;
    }
  };
};
//*------- Transacciones bind---------------

export function getTransacciones(body) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    // Imprimir el token inmediatamente después de obtenerlo

    if (!token) {
      return dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: "Token not found",
      });
    }
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/Transfer`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log("transacciones biend:",response.data.msg);

      dispatch({
        type: TYPES.GET_TRANSACCIONES,
        payload: response.data.msg,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
    }
  };
}

export function Filter_trans_codigo(data) {
  return async function (dispatch) {
    // console.log(data);
    try {
    const array =[]
    const response = await axios.post(
      `${REACT_URL_BACK}List/TransferById`,
      data,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
   
    array.push(response?.data?.msg)
      dispatch({
        type: TYPES.FILTER_TRANS_BY_CODIGO,
        payload: array,
      });
     
      return response.data
    } catch (error) {
     //console.log(error)
   // return error
    }
  };
}

// DASHBOARD DATA
export function getDataAccount() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}Banck/Account`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log(response.data.data);

      dispatch({
        type: TYPES.GET_ACCOUNT,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
//TRANSFERENCIAS
export function getMovimientos() {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.get(`${REACT_URL_BACK}Banck/ListMov`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data.data);

      dispatch({
        type: TYPES.GET_MOVIMIENTOS,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: TYPES.ERROR_MENSAJE,
        payload: error.response,
      });
    }
  };
}
// !----------------REGISTRO RED SUSA-----------------!
export function Create_tranport_business(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/AddBusiness`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}
export function Create_shop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/AddShops`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response);
      return error?.response?.data;
    }
  };
}

export function Create_tax(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Tax/AddTax`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//?CAMBIAR ESTADO ADMIN
export function adminUpdate(data) {
  //console.log(data)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(`${REACT_URL_BACK}StateAdmin`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch(getAdmin()); // Actualiza la lista de administradores después del cambio

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

//?CAMBIAR ESTADO USER
export function userUpdateState(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/User/StateUser`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      dispatch(getUsers(1));

      return response?.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//?BORRADO LOGICO USUARIO
export function deleteUserLogic(dni) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/DelUser`,
        { dni: dni },
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data)
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}

//?fotos
// export function searchPhotos(cuit) {
//   return async function (dispatch) {
//     // console.log(cuit)
//     const user = localStorage.getItem("user-log");
//     const token = JSON.parse(user)?.token;
//     try {
//       const response = await axios.post(`${REACT_APP_URLVAL}`, cuit, {
//         headers: {
//           "SERVICIOS-URBANOS-TOKEN": token,
//         },
//       });
//       dispatch({
//         type: TYPES.FOTOS,
//         payload: response?.data.data,
//       });
//       //console.log(response.data)
//       return response.data;
//     } catch (error) {
//       return error?.response?.data;
//     }
//   };
// }

//?----------PROVINCIASS----------//
export const getProvincias = () => {
  return async (dispatch) => {
    const result = await axios.get(
      "https://apis.datos.gob.ar/georef/api/provincias?campos=id,nombre"
    );
    dispatch({ type: TYPES.LIST_PROVINCES, payload: result?.data?.provincias });
  };
};
export const getMunicipios = (id) => {
  return async (dispatch) => {
    const result = await axios.get(
      `https://apis.datos.gob.ar/georef/api/municipios?provincia=${id}&campos=id,nombre&max=500`
    );

    // Ordenar alfabéticamente los municipios por su nombre
    const sortedMunicipios = result?.data?.municipios.sort((a, b) =>
      a.nombre.localeCompare(b.nombre)
    );

    dispatch({
      type: TYPES.LIST_MUNICIPIOS,
      payload: sortedMunicipios,
    });
  };
};

//?-------------------LISTAS-------------------
export function getBussinesList() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Business`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch({
        type: TYPES.LIST_BUSSINES,
        payload: response?.data?.data,
      });
      return response?.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
}

export function getShopsList() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Shops`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      // console.log(response.data.msg)
      dispatch({
        type: TYPES.LIST_SHOPS,
        payload: response.data.msg,
      });
      return response.data;
    } catch (error) {
      return error?.response?.data;
    }
  };
}
export function getTaxsList(data) {
  return async function (dispatch) {
    //console.log(data)
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/List/Tax`, data, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      dispatch({
        type: TYPES.LIST_TAXS,
        payload: response.data.msg,
      });
      //console.log(response.data.msg)
      return response.data;
    } catch (error) {
      //console.log(error);
      return error.response;
    }
  };
}
//? -----------------UPDATE STATUS--------------------
export function updateBussine(data) {
  //console.log(data)
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Business/StateBusiness`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      dispatch(getBussinesList());
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };
}
export function updateShop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Shops/StateShops`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      dispatch(getShopsList());
      return response.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
}

export function updateTax(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.put(
        `${REACT_URL_BACK}v1/Tax/StateTax`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      // console.log(response.data)
      dispatch(getTaxsList());
      return response.data;
    } catch (error) {
      // console.log(error);
      return error?.response?.data;
    }
  };
}
// *------------------- asignar cvu-----------------------

export function asignCvu(cuit) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(`${REACT_URL_BACK}AsingCVU`, cuit, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}
//  CVU COMERCIO Y EMPRESA

export function asignCvuEnty(cuit, clientId) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    let body = {
      cuit: cuit,
      clientId: clientId,
    };
    //console.log(body)
    try {
      const response = await axios.post(`${REACT_URL_BACK}AsingCVUEnty`, body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)

      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

export function getTypesUser() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(`${REACT_URL_BACK}v1/List/Benefits`, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      //console.log(response.data)
      dispatch({
        type: TYPES.LIST_TYPES,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

//*-------FILTROS TRANSPORTE----------

export function filterTrasporte(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTRO_TRANSPORT,
      payload: filtro,
    });
  };
}

// *----------------RECUPERAR CONTRASEÑA ADMIN--------------
export function sendCodePass(email) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}SendCodePass`, email);
      //console.log(response.data)
      return response.data;
    } catch (error) {
      console.log(error);
      return error?.response?.data;
    }
  };
}

export function recoverPass(data) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}InputCodePass`, data);
      // console.log(response.data)
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response?.data;
    }
  };
}

// * --------FILTROS TRANSACCIONES -------

export function filterTrans(filtro) {
  //console.log(filtro)
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTRO_TRANS,
      payload: filtro,
    });
  };
}

export const transaccion_detalle = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: TYPES.TRANSACCION_DETALLE,
        payload: id,
      });
    } catch (error) {
      // console.log(error?.response)
    }
  };
};
export function clearDetail() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLEAR_DETAIL,
    });
  };
}
// * --------FILTROS EMPRESA TRANSPORTE -------

export function filterBussiness(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_BUSSINESS,
      payload: filtro,
    });
  };
}
// * --------FILTROS SHOP-------
export function filterShop(filtro) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_SHOP,
      payload: filtro,
    });
  };
}
// ?----------ASOCIADOS ---------------

export function getAsociados(page) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    if (!token) {
      //console.log("No hay token");
    }
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/List/All/Associated`,
        {
          page: page,
        },
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(page)
      dispatch({
        type: TYPES.LIST_ASOCIADOS,
        payload: response.data.msg,
        payload2: response.data.total,
      });
    } catch (error) {
      // console.error("Error Response:", error.response);
      return error.response;
    }
  };
}
// * --------FILTROS ASOCIADOS-------
export function search_User_asociado(search) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search`,
      { dni: search, page: 1 },
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );
    dispatch({
      type: TYPES.SEARCH_ASOCIADO_BY_DNI,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
export function search_asociado_detail(id) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search`,
      id,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );

    dispatch({
      type: TYPES.ASOCIADO_DETAIL,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
export function search_User_asociado_status(search) {
  return async function (dispatch) {
    dispatch({
      type: TYPES.FILTER_ASOCIADO_BY_ESTADO,
      payload: search,
    });
  };
}
export function search_User_asociado_name(search) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;

    const response = await axios.post(
      `${REACT_URL_BACK}v1/User/List/Associate/Search`,
      search,
      {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      }
    );

    dispatch({
      type: TYPES.SEARCH_ASOCIADO_BY_NAME,
      payload: response.data.data,
      payload2: response.data?.total,
    });
  };
}
// ?----------DETALLE MOVIMIENTOS---------------

export function getMovUser(body) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      //console.log(body)
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/ListMovUser`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      dispatch({
        type: TYPES.MOV_USER,
        payload: response.data,
      });
      return response.data;
    } catch (error) {
      //console.log(error);
      return error?.response.data;
    }
  };
}
export function clearMovUser() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLEAR_MOV_USER,
    });
  };
}
//*------RECARGAR COMERCIO--------
export function creditShop(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/Credit`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response.data;
    }
  };
}
export function shopFiles(data) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Shops/Search/File`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      //console.log("Lista comprobantes",response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
//*-----------------ROLES Y PERMISOS-----------------
export function ListaPermisos(obs) {
  return async function (dispatch) {
    const user = localStorage.getItem("user-log");
    const token = JSON.parse(user)?.token;
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Permission/List`,
        obs,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      return response.data;
    } catch (error) {
      // console.log(error.response.data);
      return error?.response;
    }
  };
}
export function ListaRoles(rol) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Role/List`, rol, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function AddRol(body) {
  return async function (dispatch) {
    try {
      const response = await axios.post(`${REACT_URL_BACK}v1/Role/Add`, body, {
        headers: {
          "SERVICIOS-URBANOS-TOKEN": token,
        },
      });
      await dispatch(ListaRoles(""));

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
//ENROLLAMENT PERSON
export function ActiveBiometria(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/User/Check`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
// DETALLE EMPRESA TRANSPORTE
export function search_bussine_detail(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/OneBusiness`,
        id,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      // return response.data;
      dispatch({
        type: TYPES.BUSINESS_DETALLE,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function Retiro_dinero_empresa(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/MoneyWithdrawal`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
            SECRET: REACT_SECRET,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function exist_cvu_bussine(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/Exist`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
            SECRET: REACT_SECRET,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function bussine_transactions(cuit) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/ListMov`,
        cuit,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      console.log(response.data);
      dispatch({
        type: TYPES.MOVIMENTS_BUSSINE,
        payload: response.data?.msg,
        payload2: response.data?.total,
      });
      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

export function add_cvu_bussines(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/Business/Accounts`,
        body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data);

      return response.data;
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

// detalle comercio
export function search_shop_detail(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}v1/List/OneShops`,
        id,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      //console.log(response.data)
      // return response.data;
      dispatch({
        type: TYPES.SHOP_DETAIL,
        payload: response.data.msg,
      });
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}

// otos facetec
export function search_user_facetec(dni) {
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_APP_URLVAL}Facetec/Info`,
        dni,
        {
          headers: {
            SECRET: REACT_SECRET,
          },
        }
      );
     // console.log(response?.data?.msg);
    } catch (error) {
      //console.log(error.response.data);
      dispatch({
        type: TYPES.FOTOS,
        payload: error?.response?.data?.msg?.images
      });
      return error?.response;
    }
  };
}

export function clearPhotos() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLAEAR_PHOTOS,
    });
  };
}

//CONCILIAR 
export function conciliar_transfer(id) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}conciliar`,
        id,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      dispatch({
        type:TYPES.DETAIL_CONCILIATION,
        payload:response?.data?.msg
      })
     //console.log(response?.data);
      return response.data
    } catch (error) {
      //console.log(error.response.data);
     // return error?.response;
    }
  };
}
export function conciliar_excec(data) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}EXCEC`,
        data,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
    
        await dispatch(conciliar_transfer({id:data.id}))
      
     
      //console.log(response?.data);
      return response.data
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function clearConcilicionDetail() {
  return async function (dispatch) {
    dispatch({
      type: TYPES.CLAEAR_DETAIL_CONCILIACION
    });
  };
}

export function get_account_balance() {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;
  return async function (dispatch) {
    try {
      const response = await axios.get(
        `${REACT_URL_BACK}Accounts`,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      dispatch({
        type:TYPES.ACCOUNT_BALANCE,
        payload:response?.data
      })
     // console.log(response?.data);
      return response.data
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
export function get_moviments_accounts(body) {
  const user = localStorage.getItem("user-log");
  const token = JSON.parse(user)?.token;

  return async function (dispatch) {
    try {
      const response = await axios.post(
        `${REACT_URL_BACK}Moviment`,body,
        {
          headers: {
            "SERVICIOS-URBANOS-TOKEN": token,
          },
        }
      );
      dispatch({
        type:TYPES.ACCOUNT_MOVIMENTS,
        payload:response?.data.msg,
        payload2:response?.data?.total
      })
     
      return response.data.msg
    } catch (error) {
      //console.log(error.response.data);
      return error?.response;
    }
  };
}
