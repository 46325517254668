import React from "react";

const CargosLey = () => {
  return (
    <div>
      <div className="card" style={{padding:"20px"}}>
        <h6>Aplicar cargo de ley 301 a las transferencias entrantes</h6>
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Inactivo</label>
        </div>
        <br />
        <h6>Aplicar cargo de ley 301 a las transferencias salientes</h6>
        <div className="form-check form-switch">
            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"/>
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Inactivo</label>
        </div>
      </div>
        <button className="btn  btn-outline-success"
        style={{marginTop:"10px"}}
        >Guardar</button>
    </div>
  );
};

export default CargosLey;
