import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Create_tranport_business } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
const Transporte = () => {
  const [provincias, setProvincias] = useState([]);
const navigate = useNavigate()
  const [activo, setActivo] = useState({
    a1: false,
    a2: false,
    a3: false,
  });
  const [FormData, setFormData] = useState({
    businessName: "",
    cuit: "",
    email: "",
    dni: "",
    businessPhoneNumber: "",
    province: "",
    department: "",
    city: "-",
    county: "Argentina",
    address: "",
    postalCode: "",
    cuil: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    category: "",
    licitActivity: activo.a1,
    politicallyExposed: activo.a2,
    subjectUIF: activo.a3,
    reason: "-" ,
    reasonUIF: "-",
  });
  useEffect(() => {
    const obtenerProvincias = async () => {
      try {
        const provinciasData = [
          "Buenos Aires",
          "CABA",
          "Catamarca",
          "Chaco",
          "Chubut",
          "Córdoba",
          "Corrientes",
          "Entre Ríos",
          "Formosa",
          "Jujuy",
          "La Pampa",
          "La Rioja",
          "Mendoza",
          "Misiones",
          "Neuquén",
          "Río Negro",
          "Salta",
          "San Juan",
          "San Luis",
          "Santa Cruz",
          "Santa Fe",
          "Santiago del Estero",
          "Tierra del Fuego",
          "Tucumán",
        ];
        setProvincias(provinciasData);
      } catch (error) {
        console.error("Error al obtener las provincias:", error);
      }
    };
    obtenerProvincias();

  }, []);
useEffect(() => {
 
 // console.log(FormData, activo);
}, [FormData,activo]);

  const handleChangeForm = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...FormData,
      [name]: value, // Actualiza el estado de FormData con la provincia seleccionada
    });
  //  console.log(FormData);
  };
  const dispatch = useDispatch();
  const Registar_Empresa =async (e) => {
    e.preventDefault();
    try {
      for (const key in FormData) {
        if (FormData.hasOwnProperty(key) && FormData[key] === "") {
          Swal.fire({
            text: `Debe completar todos los campos`,
            icon: "error",
          });
          return; 
        }
      }
     setFormData({
      ...FormData,
      cuil: parseInt(FormData.cuil),
      cuit: parseInt(FormData.cuit),
      phoneNumber: parseInt(FormData.phoneNumber),
      businessPhoneNumber: parseInt(FormData.businessPhoneNumber)
     })
     const response = await dispatch(Create_tranport_business(FormData));
    // console.log(FormData)
     if(response?.status===200){
      Swal.fire({
        text: "Empresa de transporte creada con éxito",
         icon: "success",
       });
       navigate("/list/transporte")
       // Limpiar los datos del FormData
     const newFormData = Object.fromEntries(
       Object.entries(FormData).map(([key, value]) => [key, ""])
     );
     setFormData(newFormData);
     setActivo({
       a1: false,
       a2: false,
       a3: false,
     });
       //console.log(FormData);
     }else{
      Swal.fire({
        text: response?.msg || "Ocurrio un error al registrar ",
        icon: "error",
      });
     }

    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: "error",
      });
    }
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setActivo((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    // Actualizar el estado FormData con el valor del checkbox
  setFormData((prevState) => ({
    ...prevState,
    licitActivity: name === "a1" ? checked : prevState.licitActivity,
    politicallyExposed: name === "a2" ? checked : prevState.politicallyExposed,
    subjectUIF: name === "a3" ? checked : prevState.subjectUIF,
  }));
   // console.log(activo)
  };
  return (
    <div className="container ">
      <div className="card p-3">
        <h5
          className="mb-5 text-center p-2"
          style={{ color: "white", backgroundColor: "var(--naranja" }}
        >
          {" "}
          Formulario de Registro Empresa de Transporte
        </h5>
        <form className="row g-3" onSubmit={Registar_Empresa}>
          <h6>Datos de la Empresa de Transporte</h6>
          <div className="col-md-4">
            <label htmlFor="inputEmail4" className="Form-label">
              Razón social
            </label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="businessName"
              value={FormData.businessName}
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputPassword4" className="Form-label">
              CUIT
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              value={FormData.cuit}
              name="cuit"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress" className="Form-label">
              Teléfono
            </label>
            <input
              onChange={handleChangeForm}
              value={FormData.businessPhoneNumber}
              name="businessPhoneNumber"
              type="number"
              min={0}
              className="form-control"
              id="inputAddress"
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress2" className="Form-label">
              Correo Electrónico
            </label>
            <input
              onChange={handleChangeForm}
              value={FormData.email}
              name="email"
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder=""
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />
          <h6>Datos de ubicación</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Pais
            </label>
            <input
              type="text"
              name="country"
              className="form-control"
              value={FormData.county}
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputState" className="Form-label">
              Provincia
            </label>

            <select
              className="form-control"
              value={FormData.province}
              name="province"
              onChange={(e) =>
                setFormData({ ...FormData, province: e.target.value })
              }
            >
              <option hidden></option>,
              {provincias.map((provincia, index) => (
                <option key={index} value={provincia}>
                  {provincia}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Localidad
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.department}
              name="department"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Dirección
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.address}
              name="address"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputZip" className="Form-label">
              Código Postal
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.postalCode}
              name="postalCode"
              onChange={handleChangeForm}
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Datos del Representante legal</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              CUIL
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.cuil}
              name="cuil"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              DNI
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.dni}
              name="dni"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Telefóno
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              value={FormData.phoneNumber}
              name="phoneNumber"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Nombres
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.name}
              name="name"
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Apellidos
            </label>
            <input
              type="text"
              className="form-control"
              value={FormData.lastName}
              name="lastName"
              onChange={handleChangeForm}
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Declaración jurada</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Rubro
            </label>
            <select
              className="form-control"
              name="category"
              value={FormData.category}
              onChange={handleChangeForm}
            >
              <option value="" disabled>
                {" "}
                Seleccionar rubro
              </option>
              <option value="supermercado">Supermercado</option>
              <option value="viajes_turismo">Viajes y Turismo</option>
              <option value="indumentaria">Indumentaria</option>
              <option value="restaurante">Restaurante</option>
              <option value="automovil">Automovil</option>
              <option value="hogar">Hogar</option>
              <option value="servicios">Servicios</option>
              <option value="otro">Otro</option>
            </select>
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declara que los fondos y valores a aplicar provienen de actividades
            lícitas
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a1"
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.a1 ? "No" : "Si"}
            </label>
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser Persona Expuesta Politicamente
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a2"
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.a2 ? "No" : "Si"}
            </label>
          </div>
          <div className="col-6">
            <input
              type="text"
              name="reason"
              className=" form-control"
              placeholder="Motivo"
              disabled={!activo.a2}
              value={FormData.reason}
              onChange={handleChangeForm}
            />
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser sujeto obligado ante UIF
          </label>
          <div className="form-check form-switch ">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a3"
              onChange={handleChange}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
              {!activo.a3 ? "No" : "Si"}
            </label>
          </div>
          <div className="col-6">
            <input
              type="text"
              className=" form-control"
              placeholder="Motivo"
              name="reasonUIF"
              disabled={!activo.a3}
              value={FormData.reasonUIF}
              onChange={handleChangeForm}
            />
          </div>
          <div className="col-12">
            <button
              style={{
                border: "solid 1px var(--naranja",
                margin: "20px",
                fontSize: "14px",
              }}
              className="btn btn-outline "
              type="submit"
            >
              Registrar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Transporte;
