import { FaShop } from "react-icons/fa6";
import { MdOutlineSupportAgent, MdExitToApp, MdComputer } from "react-icons/md";
import { IoAddCircleOutline } from "react-icons/io5";
import logo from "../../img/susa-logo.png";
import s from "../../styles/sideBar.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { HiOutlineReceiptTax } from "react-icons/hi";
import { MdListAlt } from "react-icons/md";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { getUsers, LogOut, getAdmin } from "../../redux/actions";
import { FaUsers, FaUsersCog, FaBus, FaUserCog, FaUserCircle } from "react-icons/fa";
import { BiTransfer } from "react-icons/bi";
import { FaChildren } from "react-icons/fa6";
import classnames from 'classnames';
import { RiUserSettingsLine } from "react-icons/ri";
const Sidebar2 = () => {
  const dispatch = useDispatch();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const usuario = localStorage.getItem("user-log");
  const userRole = JSON.parse(usuario)?.data_user?.role; 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (usuario) {
      setLoggedInUser(JSON.parse(usuario));
    }


    if (location.pathname === '/home') {
      navigate('/usuarios');
    }
  }, [usuario, location.pathname, navigate]);

  const handleLogout = () => {
    dispatch(LogOut());
    localStorage.removeItem("user-log");
    navigate("/");
  };

  const reload = () => {
    dispatch(getUsers());
    dispatch(getAdmin());
  };

  return (
    <div className="d-flex">
      <nav className={` ${s.sidebar}`}>
        <div className={`d-flex flex-column ${s.sidebarContent}`}>
          <Link to="/home" onClick={reload} className="text-center mb-4 bg-white p-2">
            <img src={logo} alt="logo" className={s.logo} />
          </Link>
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link
                to="/usuarios"
                className={classnames('nav-link text-white fs-6', s.navLink, {
                  [s.active]: location.pathname === '/usuarios'
                })}
              >
                <FaUsers /> Admin. de Usuarios
              </Link>
              <hr />
            </li>
            <li className="nav-item">
              <Link to="/list/asociados"  className={classnames('nav-link text-white fs-6', s.navLink, {
                  [s.active]: location.pathname === '/list/asociados'
                })}>
                <FaChildren /> Admin. de Asociados
              </Link>
              <hr />
            </li>
            <li className="nav-item" hidden={userRole!=="superadmin"}>
              <Link to="/operadores"  className={classnames('nav-link text-white fs-6', s.navLink, {
                  [s.active]: location.pathname === '/operadores'
                })}>
                <FaUsersCog /> Admin. operadores de consola
              </Link>
              <hr />
            </li>
            <li className={`nav-item dropdown ${s.dropdown}`} hidden={userRole==="atc"}>
              <a
                className={`nav-link dropdown-toggle text-white fs-6 ${s.navLink}`}
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <MdComputer style={{ fontSize: "20px" }} /> Admin. red Susa
              </a>
              <ul className={`dropdown-menu bg-dark ${s.dropdownMenu}`}>
                <li>
                  <Link
                    className={classnames('dropdown-item text-white', s.dropdownItem, {
                      [s.active]: location.pathname === '/list/transporte'
                    })}
                    to="/list/transporte"
                  >
                    <FaBus style={{ marginRight: "5px" }} /> Lista de Empresas Transporte
                  </Link>
                </li>
                <li>
                  <Link
                    className={classnames('dropdown-item text-white', s.dropdownItem, {
                      [s.active]: location.pathname === '/list/comercio'
                    })}
                    to="/list/comercio"
                  >
                    <FaShop style={{ marginRight: "5px" }} /> Lista de Comercios
                  </Link>
                </li>
                <li>
                  <Link
                    className={classnames('dropdown-item text-white', s.dropdownItem, {
                      [s.active]: location.pathname === '/list/impuestos'
                    })}
                    to="/list/impuestos"
                  >
                    <MdListAlt style={{ marginRight: "5px" }} /> Lista de Impuestos
                  </Link>
                </li>
              </ul>
            </li>
            <hr hidden={userRole==="atc"}/>
            <li className="nav-item" hidden={userRole!=="superadmin"}>
              <Link
                to="/list/transacciones"
                className={classnames('nav-link text-white fs-6', s.navLink, {
                  [s.active]: location.pathname === '/list/transacciones'
                })}
              >
                <BiTransfer fontSize={20} /> Transacciones Bind
              </Link>
            </li>
           
           
          </ul>
        </div>
        <footer className={`mt-auto ${s.footer}`}>
          <div className={s.containerNav}>
            <div className={s.logo_user}>
              <div>
                <Link to={""} className={s.user}>
                  <FaUserCircle
                    title="Usuario conectado"
                    style={{
                      fontSize: "25px",
                      marginRight: "5px",
                      color: "white",
                    }}
                  />
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      marginTop: "16px",
                    }}
                  >
                    {loggedInUser ? `${loggedInUser?.data_user?.name}` : ""}
                  </p>
                </Link>
              </div>
            </div>
            <MdExitToApp
              onClick={handleLogout}
              title="Salir"
              style={{
                fontSize: "25px",
                marginLeft: "5px",
                color: "white",
                cursor: "pointer",
              }}
            />
          </div>
        </footer>
      </nav>
      <div className={`${s.content} flex-grow-1`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar2;

