import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../styles/Users.module.css";
import Paginado from "../Consola usuarios/Paginado";
import { Spiner } from "../Spiner";
import { Link } from "react-router-dom";
import {
  FaUserMinus,
  FaRegCheckCircle,
  FaRegTimesCircle,
  FaRProject,
  FaTimes,
  FaCheck,
  FaEdit,
} from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { IoMdBus } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa6";
import Swal from "sweetalert2";
import { IoReload } from "react-icons/io5";
import {
  getBussinesList,
  updateBussine,
  asignCvuEnty,
  search_bussine_detail,
} from "../../redux/actions";
import Switch from "react-switch";
import { FaUserAltSlash, FaUserCheck } from "react-icons/fa";
import Navbar from "./NavSearch";
import { RiBankLine } from "react-icons/ri";
import Modal from "react-modal";
import BussinessDetail from "../Bussiness/BussinessDetail";
import { FaRegEye } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
const Transporte = () => {
  const list = useSelector((state) => state.bussines);
  const [loading, setLoading] = useState(true);
  const [pagina, setPagina] = useState(1);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [selectedCuit, setSelectedCuit] = useState(null);
  const [verPorPagina, setVerPorPagina] = useState(10);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBussinesList());
    //console.log(list)
    setTimeout(() => {
      if (!list?.length) {
        setShowNoDataMessage(true);
      }
    }, 2000);
  }, []);

  const asignarCvu = async (cuit, clientId) => {
    //console.log(cuit)
    try {
      const response = await dispatch(asignCvuEnty(cuit, clientId));

      if (response?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Ok",
          text: "CVU asignado con éxito!",
        });
        setTimeout(() => {
          dispatch(getBussinesList());
          setPagina(pagina);
        }, 300);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.msg || "Ups! Ocurrió un error",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.msg || "Ups! Ocurrió un error",
      });
    }
  };

  const updateState = async (cuit, newState) => {
    try {
      const data = { cuit, state: newState };
      const response = await dispatch(updateBussine(data));
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: `Se ${newState ? "habilitó" : "inhabilitó"} a esta empresa`,
        });
        // Actualizar el estado local de administradores después de la actualización
        await dispatch(getBussinesList()); // Actualizar la lista de administradores después de la actualización
      }
    } catch (error) {
      console.error("Error updating administrator state:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado del administrador",
      });
    }
  };
  const reload = () => {
    setLoading(true);
    dispatch(getBussinesList());
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const fnOpenModal = (id,cuit) => {
 
    setSelectedCuit(cuit)
    setOpenModal(true);
    dispatch(search_bussine_detail({ _id: id }));
  };

  const fncloseModal = () => {
    setOpenModal(false);
   
  };

  const maximo = Math.ceil(list?.length / verPorPagina);
  return (
    <div className="">
      <div className="container align-items-center">
        <div>
          <div className="d-flex align-items-center">
            <IoReload
              title="Recargar"
              onClick={reload}
              style={{
                fontSize: "25px",
                color: "orange",
                fontWeight: "bold",
                cursor: "pointer",
                marginLeft: 18,
              }}
            />
            <h5
              style={{ margin: "10px", marginLeft: "20px" }}
              className="text-center"
            >
              Admin. Empresas de transporte
            </h5>
            <Link
              to={"/add/transporte"}
              style={{
                textDecoration: "none",
                color: "black",
                marginLeft: "50%",
              }}
            >
              <IoMdBus
                style={{
                  fontSize: "22px",
                  color: "orange",
                  marginRight: "3px",
                }}
              />
              Registrar Empresa
            </Link>
          </div>
        </div>
        <Navbar setPagina={setPagina} />
        {list && list.length > 0 ? (
          <div id="#admin">
            <div className="row ">
              <div className="d-flex ">
                <p className="fs-6">Total de Empresas: {list?.length}</p>
              </div>
            </div>
            <table className={`table`}>
              <thead className="table-info">
                <tr>
                  <th>Nombre de la Empresa</th>
                  <th>CUIT</th>
                  <th>Correo</th>
                  <th>Estado</th>
                  <th>Ver detalle</th>
                  <th>Asignar CVU</th>
                </tr>
              </thead>
              <tbody>
                {list
                  .slice(
                    (pagina - 1) * verPorPagina,
                    (pagina - 1) * verPorPagina + verPorPagina
                  )
                  .map((adm) => (
                    <tr key={adm?._id} style={{ textAlign: "left" }}>
                      <td>{adm?.businessName}</td>
                      <td>{adm?.cuit}</td>
                      <td>{adm?.email}</td>
                      <td>
                    
                        <Switch
                          onChange={(checked) => updateState(adm.cuit, checked)}
                          checked={adm.state}
                        />
                      </td>
                      <td>
                        <button
                          className="btn "
                          onClick={() => fnOpenModal(adm?._id,adm?.cuit)}
                        >
                          <FaRegEye size={22} />
                        </button>
                      </td>
                      <td>
                        {adm.cvu === "Pendiente" ? (
                          <div>
                            <button
                              className="btn "
                              onClick={() =>
                                asignarCvu(adm?.cuit, adm.clientId)
                              }
                            >
                              <RiBankLine fontSize={18} /> Asignar CVU
                            </button>
                          </div>
                        ) : (
                          <div >
                            <p style={{fontSize:16, color:"#2e2e2e", marginLeft:10}}>
                            <FaCircleCheck
                              size={20}
                              style={{ color: "#3d83b8" }}
                            /> Asignado

                            </p>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Paginado data={maximo} pagina={pagina} setPagina={setPagina} />
          </div>
        ) : (
          showNoDataMessage && (
            <div className="alert alert-warning" role="alert">
              No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
            </div>
          )
        )}
      </div>
      <Modal
        isOpen={openModal}
        className={styles.modal}
        overlayClassName={styles.Overlay} //fondo atras
        onRequestClose={fncloseModal}
        contentLabel="Detalle Asociado"
      >
        <BussinessDetail  cuit={selectedCuit} closeModaldetail={fncloseModal} />
      </Modal>
    </div>
  );
};

export default Transporte;
