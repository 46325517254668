import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AddRol, ListaPermisos, ListaRoles } from "../redux/actions";
import Swal from "sweetalert2";
import { getRoles } from "@testing-library/react";
const RolesPermiss = () => {
  const [listPermis, setlistPermiss] = useState([]);
  const [listRol, setlistRol] = useState([]);

  const dispatch = useDispatch();
  const [data,setData]= useState({
      role:"",
      permission:""
  })
  useEffect(() => {
    getPermis();
   
  }, []);
  const getPermis = async () => {
    const response = await dispatch(ListaPermisos(""));
    setlistPermiss(response.msg);
    const responseRol = await dispatch(ListaRoles(""));
    setlistRol(responseRol.msg);
    //console.log(listRol);
  };
const onChangeData =(e)=>{
    const { name, value } = e.target;
    setData({
        ...data,
       [name]: value

    })
}
  const agregarRol =async()=>{
   const res= await dispatch(AddRol(data))
   if(res.status===200){
    Swal.fire({
        text:"Permiso Asignado",
        icon:"success"
    })
   getPermis()
   setData({role:"",permission:""})
   }else{
    Swal.fire({
        text:res?.msg || "Ocurrió un error",
        icon:"error"
    })
   }
   //console.log(res)
  }
  return (
    <div className="container ">
      <div className="card p-3">
        <h5 className="ms-3">Roles y Permisos</h5>

        <div className="p-2">
          <div className="row justify-content-start">
            <div className="col-4">
          <label htmlFor="" className="" >Agregar Rol</label>
              <input
                type="text"
                value={data.role}
                className="form-control mb-3"
                placeholder="Nombre del rol"
                name="role"
                onChange={onChangeData}
              />
              <label htmlFor="">Permisos</label>
              <select className="form-control" value={data.permission} name="permission" onChange={onChangeData}>
                <option value="" disabled>
                  Seleccionar permiso
                </option>
                {listPermis?.map((el) => (
                  <option key={el._id} value={el._id}>
                    {el.observation}
                  </option>
                ))}
              </select>
              <button
                style={{
                  border: "solid 1px var(--naranja",
                  marginTop: "20px",
                  fontSize: "14px",
                }}
                className="btn btn-outline "
                type="submit"
                onClick={agregarRol}
              >
                Agregar
              </button>
            </div>

            <div className="col-8">
              <h5>Lista de roles</h5>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Rol</th>

                    <th scope="col">Estado</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {listRol?.map((rol, i) => (
                    <tr key={i}>
                      <td>{rol.role}</td>
                      <td>{rol.state ? "Activo" : "Inactivo"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolesPermiss;
