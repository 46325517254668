import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shopFiles } from "../../redux/actions";
import { FaRegFile } from "react-icons/fa";
import { Spiner } from "../Spiner";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import Paginado from "../Consola usuarios/Paginado";
import { BiSearch } from "react-icons/bi";
import { compose } from "redux";

const getCurrentDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const ListComprobantes = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [comprobantes, setComprobantes] = useState([]);
  const [Selectfecha, setSelectFecha] = useState(getCurrentDate());
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const maximo = Math.ceil(comprobantes?.length / 10);

  useEffect(() => {
    listaComprobantes();
  }, [id, Selectfecha, dispatch,loading]);

  const listaComprobantes = async () => {
    const send = {
      cuit: parseInt(id),
      fecha: Selectfecha,
    };
   setPage(1)
    const response = await dispatch(shopFiles(send));
    if (response.status === 200) {
      setComprobantes(response?.urls);
      setLoading(false);
  
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setLoading(true);
    setTimeout(() => setLoading(false), 300); // Simulating loading delay
  };

  const onChangueDate = (e) => {
    setSelectFecha(e.target.value);
    setLoading(true);
    setTimeout(() => setLoading(false), 300);
  };

  if (loading) return <Spiner />;

  return (
    <div className="container">
      <div className="d-flex p-2 align-items-center">
        <Link to={"/list/comercio"}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
        <label htmlFor="">Ver por fecha:</label>
        <input
          className="form-control"
          style={{ width: 200 }}
          type="date"
          value={Selectfecha}
          onChange={onChangueDate}
        />
      </div>
      {loading ? (
        <Spiner />
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Nro de Tramite</th>
              <th scope="col">Fecha de creación</th>
              <th scope="col">Comercio</th>
              <th scope="col">Estado</th>
              <th scope="col">Monto$</th>
              <th scope="col">Comprobantes</th>
              <th scope="col">Observación</th>
            </tr>
          </thead>
          <tbody>
            {comprobantes?.length ? (
              comprobantes
                ?.slice((page - 1) * 10, (page - 1) * 10 + 10)
                ?.map((el, i) => (
                  <tr key={i}>
                    <td scope="row">{el.data.nTramite}</td>
                    <td scope="row">{el.data?.dateCreation.slice(0, 10)}</td>
                    <td scope="row">{el.data?.nameShop}</td>
                    <td scope="row">{el.data?.state ? "Acreditado" : "Sin saldar"}</td>
                    <td scope="row">{el?.data?.amount}</td>
                    <td>
                      <a style={{ textDecoration: "none" }} href={el.url} target="_blank">
                        <FaRegFile /> Ver archivo
                      </a>
                    </td>
                    <td scope="row">{el?.data?.observation || "-"}</td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="7">
                  <div className="alert alert-warning" role="alert">
                    No se encontraron comprobantes en la fecha.<BiSearch style={{ fontSize: "30px" }} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <Paginado pagina={page} data={maximo} setPagina={setPage} />
    </div>
  );
};

export default ListComprobantes;

