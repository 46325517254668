
import { useSelector } from 'react-redux';
import React from "react";
import { Navigate, Outlet } from "react-router-dom";


export default function PrivateRoute({role,children}) {
  const user = window.localStorage.getItem("user-log")
  const userRole = JSON.parse(user)?.data_user?.role; 
// console.log(userRole);
//  console.log(role)
 if (!role.includes(userRole)) {
  return <Navigate to="/usuarios" />; // Redirige a una página de error o al login si no tiene acceso
}

return children;
  
}