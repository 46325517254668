import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useLocation } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BiSearch } from "react-icons/bi";
import moment from "moment-timezone";
import s from "../../styles/Users.module.css";
import {
  filterMovByMes,
  getMovUser,
  search_User_detalle,
  clearMovUser,
} from "../../redux/actions";
import { Spiner } from "../Spiner";
import Paginado from "./Paginado"; // Asegúrate de tener el componente de paginación

const Movimientos = (body) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user_detalle);
  const mov = useSelector((state) => state.mov_user);
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectType, setSelectedType] = useState("");
  const closeModalMov=body.closeModalMov //funcion cerrar modal
  useEffect(() => {
  
    if (body) {
      const fetchMovements = async () => {
        setLoading(true);
        const data = {
          cuit: body.cuit,
          page: page,
          type: selectType,
        };
        // Ejecutar la acción para obtener los movimientos
        const response = await dispatch(getMovUser(data));

        if (response) {
          setLoading(false);
        }
      };

      fetchMovements(); // Llamar la función async
    }
    return () => {
      dispatch(clearMovUser());
    };
  }, [dispatch, page, selected, selectType, body]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const filterType = (e) => {
    setSelectedType(e);
    setPage(1);
  };

  return (
    <div>
      <div className="pb-2 d-flex align-items-center">
        <div className="d-flex align-items-center ms-3">
          <Link onClick={closeModalMov}>
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange" }}
            />
          </Link>
        </div>
        <label htmlFor="">Tipo de movimiento:</label>
        <select
          className="form-control"
          style={{ width: 300 }}
          onChange={(e) => filterType(e.target.value)}
        >
          <option value="">Todos los movimientos</option>
          <option value="Transferencia Enviada">Tranferencias Enviadas</option>
          <option value="Transferencia Recibida">
            Tranferencias Recibidas
          </option>
          <option value="Pago de Pasaje">Pago de pasaje (Check In)</option>
          <option value="Devolución de Pasaje">
            Devolución de pasaje (Check Out)
          </option>
          <option value="Compra de Saldo">Compra de saldo</option>
        </select>
      </div>
      <div
        className={`${s.containerMovimientos}`}
        id="#movimientos"
        style={{ padding: "20px" }}
      >
        <div>
          {loading ? (
            <Spiner />
          ) : !mov?.lista || mov?.lista?.length === 0 ? (
            <div className="alert alert-warning" role="alert">
              No se encontraron movimientos.
              <BiSearch style={{ fontSize: "30px" }} />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Fecha de transacción</th>
                  <th scope="col">Código SUSA</th>
                  <th scope="col">Tipo de transacción</th>
                  <th scope="col">Destino</th>
                  <th scope="col">Detalle</th>
                  <th scope="col">Monto(AR$)</th>
                </tr>
              </thead>
              <tbody>
                {mov?.lista?.map((el) => (
                  <tr key={el._id}>
                    <td>
                      {moment
                        .utc(el.timestamp)
                        .tz("America/Argentina/Buenos_Aires")
                        .format("DD-MM-YYYY") +
                        " " +
                        el.timestamp.slice(11, 16)}{" "}
                      hs
                    </td>
                    <td>{el.metadata?.origin_id}</td>
                    <td>{el.movimiento?.types}</td>
                    <td>{el.movimiento?.cvuDest}</td>
                    <td>{el.movimiento?.observations}</td>
                    <td>{el.movimiento?.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
              <div style={{ marginLeft: "10px" }}>
                <Paginado
                  pagina={page}
                  data={mov?.totalPage}
                  setPagina={handlePageChange}
                />
              </div>
    </div>
  );
};

export default Movimientos;
