// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Paginado_num__h9egt{
    color :rgb(234, 136, 44) !important
}
.Paginado_activePage__B\\+N1e a{
background-color: var(--naranja)  !important;
color: whitesmoke !important;
border-radius: 3px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/Paginado.module.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;AACA,4CAA4C;AAC5C,4BAA4B;AAC5B,6BAA6B;AAC7B","sourcesContent":[".num{\n    color :rgb(234, 136, 44) !important\n}\n.activePage a{\nbackground-color: var(--naranja)  !important;\ncolor: whitesmoke !important;\nborder-radius: 3px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"num": `Paginado_num__h9egt`,
	"activePage": `Paginado_activePage__B+N1e`
};
export default ___CSS_LOADER_EXPORT___;
