import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/Tabs.css";
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Swal from "sweetalert2";
import CargosLey from "./CargosLey";

import "../../App.css";
import {
  Update_user,
  getUsers,
  userUpdateState,
  deleteUserLogic,
  searchPhotos,
  search_User_detalle,
  asignCvu,
  getTypesUser,
  associatesList,
  clearDetailUser,
  ActiveBiometria,
  search_asociado_detail,
  clearPhotos,
} from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import { MdDeleteForever } from "react-icons/md";
import { FaUserAltSlash, FaUserCheck } from "react-icons/fa";
import Switch from "react-switch";
import { DetalleAsociado } from "./DetalleAsociado";
import { Spiner } from "../Spiner";
import { BiSearch } from "react-icons/bi";
const DetalleUsuarios = ({ closeModalUser }) => {
  const user = useSelector((state) => state.user_detalle);
  const photos = useSelector((state) => state.fotos);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [data, setData] = useState({});
  const listBeneficios = useSelector((state) => state.listTypes);
  const [checked, setChecked] = useState(false);
  const [checkedBiometic, setCheckedBiometic] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selecteBeneficio, setSelectedBeneficio] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = user?.data?.[0] || user?.data || {};

  useEffect(() => {
    dispatch(getTypesUser());
    //console.log(photos)

    setData({
      email: userData?.email || "",
      user: userData?.email || "",
      idPassenger: userData?.idPassenger || "",
      idType: userData?.idType || "",
      name: userData?.name || "",
      lastName: userData?.lastName || "",
      birthDate: userData?.birthDate?.slice(0, 10) || "",
      departament: userData?.department || "",
      city: userData?.city || "",
      dni: userData?.dni ? parseInt(userData?.dni) : "",
      cuit: userData?.cuit ? parseInt(userData?.cuit) : "",
      phoneNumber: userData?.phoneNumber || "",
      address: userData?.address || "",
      postalCode: userData?.postalCode || "",
      province: userData?.province || "",
    });
    setChecked(userData?.state || false);
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, [user, dispatch, photos]);
  useEffect(() => {
    return () => {
      dispatch(clearPhotos());
    };
  }, []);
  const handleChange = async (cuit, checked) => {
    setChecked(checked);

    const newState = checked ? "Activo" : "Inactivo";
    // console.log(newState)
    const data = { cuit: cuit, state: newState };
    const response = await dispatch(userUpdateState(data));
    //console.log(data);
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Ok",
        text: "Usuario actualizado correctamente",
      });
      dispatch(search_User_detalle({ _id: userData._id }));
    } else {
      Swal.fire({
        icon: "error",
        title: "error",
        text: response?.msg || "ups! ocurrió un error ",
      });
    }
  };

  const handleChangeBiometria = async (_id, checkedBiometic) => {
    setCheckedBiometic(checkedBiometic);
    const newState = checkedBiometic ? true : false;
    const data = { _id: _id, renaper: newState };
    //console.log(data)
    const response = await dispatch(ActiveBiometria(data));
    //console.log(data);
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Ok",
        text: "Se actualizó el estado de biomatría",
      });
      dispatch(search_User_detalle({ _id: userData._id, page: 1 }));
    } else {
      Swal.fire({
        icon: "error",
        title: "error",
        text: response?.msg || "ups! ocurrió un error ",
      });
    }
  };
  const cambiarTab = (numTab) => {
    setActiveTab(numTab);
  };

  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    //console.log(data);
  };

  const guardarCambios = async () => {
    try {
      if (data) {
        setData({
          ...data,
        });
        const response = await dispatch(
          Update_user(user?.data?.dni, { ...data, cuit: parseInt(data?.cuit) })
        );
        if (response?.status === 200) {
          Swal.fire({
            text: "Datos actualizados con éxito",
            icon: "success",
          });

          dispatch(search_User_detalle({ _id: userData._id, page: 1 }));
          await dispatch(getUsers(1, "Activo"));
        } else {
          Swal.fire({
            text: response?.msg || "Ocurró un error",
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        text: error?.message,
        icon: "error",
      });
    }
  };

  const openModal = (photo) => {
    const convertPhoto = `data:image/jpeg;base64,${photo}`;
    setSelectedPhoto(convertPhoto);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedPhoto(null);
  };
  const asignarCvu = async () => {
    //console.log(user.data)
    const response = await dispatch(asignCvu({ cuit: user?.data[0]?.cuit }));
    // console.log( user?.data[0].cuit)
    if (response?.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Ok",
        text: "CVU asignado con éxito!",
      });
      setTimeout(() => {
        navigate("/home");
      }, 300);
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: response?.msg || "Ups! Ocurrió un error",
      });
    }
  };

  const balance = userData?.currentBalance?.toFixed(2);
  const formattedBalance = parseFloat(balance).toLocaleString("es-ES", {
    minimumFractionDigits: 2,
  });

  return (
    <div className="container mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <Link onClick={closeModalUser}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
      </div>
      <Nav tabs className="w-100">
        <NavItem>
          <NavLink
            onClick={() => cambiarTab("1")}
            className={activeTab === "1" ? "activeTab baseTab" : "baseTab"}
          >
            Datos del usuario
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "activeTab baseTab" : "baseTab"}
            onClick={() => cambiarTab("2")}
          >
            Fotos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "3" ? "activeTab baseTab" : "baseTab"}
            onClick={() => cambiarTab("3")}
          >
            Cargos por ley
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "4" ? "activeTab baseTab" : "baseTab"}
            onClick={() => cambiarTab("4")}
          >
            Asociados
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div style={{ width: "100%" }}>
            {loading ? (
              <Spiner />
            ) : (
              <>
                {user ? (
                  <div key={user?.data?._id} className="card mb-3">
                    <div className="card-body">
                      <div className="row">
                        {/* Primera grilla */}
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item align-items-center">
                              <b>Nombre:</b>{" "}
                              <input
                                className="form-control "
                                type="text"
                                name="name"
                                value={data?.name || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Apellido:</b>{" "}
                              <input
                                className="form-control"
                                type="text"
                                name="lastName"
                                value={data?.lastName || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center justify-content-between">
                              <b>DNI:</b>{" "}
                              <input
                                className="form-control"
                                type="text"
                                name="dni"
                                value={data?.dni || ""}
                                disabled
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>CUIT:</b>{" "}
                              <input
                                disabled
                                className="form-control"
                                type="text"
                                name="cuit"
                                value={data?.cuit || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Fecha de nacimiento:</b>{" "}
                              <input
                                disabled
                                className="form-control"
                                type="date"
                                name="birthDate"
                                value={data?.birthDate || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Número de teléfono: </b>
                              <input
                                className="form-control"
                                type="text"
                                name="phoneNumber"
                                value={data?.phoneNumber || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Saldo $ </b>
                              <input
                                className="form-control"
                                type="text"
                                value={formattedBalance || ""}
                                disabled
                              />
                            </li>

                            <li className="list-group-item align-items-center">
                              <b>Biometría:</b>
                              <div className="d-flex">
                                <p
                                  style={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                    marginRight: 20,
                                  }}
                                >
                                  {userData?.renaper}
                                </p>

                                <Switch
                                  onChange={(checkedBiometic) =>
                                    handleChangeBiometria(
                                      userData?._id,
                                      checkedBiometic
                                    )
                                  }
                                  checked={userData?.renaper}
                                />

                                <div></div>
                              </div>
                            </li>
                          </ul>
                        </div>

                        {/* Segunda grilla */}
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item  align-items-center">
                              <b>Dirección: </b>
                              <input
                                className="form-control"
                                type="text"
                                name="address"
                                value={data?.address || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item align-items-center">
                              <b>Localidad: </b>
                              <input
                                className="form-control"
                                type="text"
                                name="city"
                                value={data?.city || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Correo:</b>
                              <input
                                className="form-control"
                                type="text"
                                name="email"
                                value={data?.email || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item  align-items-center">
                              <b>Código Postal:</b>
                              <input
                                className="form-control"
                                type="text"
                                name="postalCode"
                                value={data?.postalCode || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item align-items-center">
                              <b>Provincia:</b>
                              <input
                                className="form-control"
                                type="text"
                                name="province"
                                value={data?.province || ""}
                                onChange={handleDataChange}
                              />
                            </li>
                            <li className="list-group-item align-items-center">
                              <b>Beneficio:</b>
                              <select
                                className="form-control"
                                name="idType"
                                value={data?.idType}
                                onChange={(e) =>
                                  setData({ ...data, idType: e.target.value })
                                }
                              >
                                {listBeneficios?.msg?.map((el, i) => (
                                  <option key={el._id} value={el._id}>
                                    {el?.name}
                                  </option>
                                ))}
                              </select>
                            </li>
                            <li className="list-group-item align-items-center">
                              <b>Estado:</b>
                              <div className="d-flex">
                                <p
                                  style={{
                                    alignItems: "center",
                                    cursor: "pointer",
                                    marginRight: 20,
                                  }}
                                >
                                  {userData.state}
                                </p>

                                <Switch
                                  onChange={(checked) =>
                                    handleChange(userData.cuit, checked)
                                  }
                                  checked={userData.state === "Activo"}
                                />

                                <div></div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-around">
                      <button
                        type="button"
                        className="btn btn-outline-success m-2 "
                        onClick={guardarCambios}
                      >
                        {" "}
                        Guardar Cambios
                      </button>
                      {!userData.cvu && (
                        <button
                          onClick={asignarCvu}
                          className="btn btn-sm btn-outline-primary m-2  "
                        >
                          Asignar CVU
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="alert alert-warning" role="alert">
                    No se encontraron datos.
                    <BiSearch style={{ fontSize: "30px" }} />
                  </div>
                )}
              </>
            )}
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="row">
            <div className="col">
              <div className="card" style={{ margin: "5%", width: "250px" }}>
                <img
                  src={`data:image/jpeg;base64,${photos?.["dni-front"]}` || ""}
                  alt="User Photo"
                  className="card-img-top"
                  onClick={() => openModal(photos["dni-front"])}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="col">
              <div className="card" style={{ margin: "5%", width: "250px" }}>
                <img
                  src={`data:image/jpeg;base64,${photos?.["dni-back"]}` || ""}
                  alt="User Photo"
                  className="card-img-top"
                  onClick={() => openModal(photos["dni-back"])}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="col">
              <div className="card" style={{ margin: "5%", width: "250px" }}>
                <img
                  src={`data:image/jpeg;base64,${photos?.selfie}` || ""}
                  alt="User Photo"
                  className="card-img-top"
                  onClick={() => openModal(photos.selfie)}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={{
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "50%",
                transform: "translate(-50%, -50%)",
                height: "70%",
                width: "50%",
                cursor: "pointer",
              },
            }}
          >
            <IoClose
              onClick={closeModal}
              style={{ fontSize: "30px", color: "red" }}
            />
            {selectedPhoto && (
              <img
                key={selectedPhoto}
                src={selectedPhoto}
                alt="User Photo"
                style={{ width: "100%" }}
              />
            )}
          </Modal>
        </TabPane>
        <TabPane tabId="3">
          <CargosLey />
        </TabPane>
        <TabPane tabId="4">
        {userData?.asociados?.length ? (
          <div>
            <div className="container ">
              <div className="row align-items-start ">
                <div className="col" style={{ fontSize: 15 }}>
                  Nombre y Apellido
                </div>
                <div className="col" style={{ fontSize: 15 }}>
                  DNI
                </div>
                <div className="col" style={{ fontSize: 15 }}>
                  Estado
                </div>
              </div>
            </div>

        {  
              userData?.asociados?.map((el, i) => (
                <div key={i} className="card row align-items-start pt-3">
                  <div className="row align-items-start pt-3">
                    <div className="col">
                      {el?.name} {el?.lastName}
                    </div>
                    <div className="col">{el?.dni}</div>
                    <div className="col">
                      <Switch
                        onChange={(checked) => handleChange(el?.cuit, checked)}
                        checked={el?.state === "Activo"}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
            ) : (
              <div className="alert alert-warning" role="alert">
                No tiene usuarios asociados 
               
              </div>
            )}
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DetalleUsuarios;
