import React, { useEffect ,useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Spiner } from '../Spiner';
import Swal from "sweetalert2";
import { GiReceiveMoney } from "react-icons/gi";
import Modal  from 'react-modal';
import { IoCloseCircleOutline } from "react-icons/io5";
import { exist_cvu_bussine } from '../../redux/actions';
const BussinessDetail = (params) => {  
  const bussine = useSelector((state) => state.detalle_bussiness)
  const [loading,setLoading]= useState(true)
  const [showMessage,setShowMessage]= useState(false)

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch()
  const [form,setForm]= useState({
    cuit: params.cuit,
    cvuDest: "",
    amount: "",
    concept: "VARIOS"
  })
  useEffect(() => {
   

    setTimeout(() => {
    setLoading(false)
    }, 300);

  }, [])  
  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  const onChangeForm =(e)=>{
      setForm({
        ...form,
      [ e.target.name]: e.target.value
      })
     // console.log(form)
  }

  const RetiroDinero =async()=>{
    const exist={
      cuit:params.cuit,
      cvuDest:form.cvuDest
    }
    const res = await dispatch(exist_cvu_bussine(exist))
    console.log(res)
  }

  return (
    <div className="container ">
      <div className='d-flex justify-content-between mb-3'>
        <Link onClick={params.closeModaldetail}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
        {
         bussine &&(
        <button  className='btn btn-outline-primary d-flex align-items-center' onClick={openModal}>
          Retiro de Dinero
         <GiReceiveMoney size={23} style={{color:"green"}}/>
        </button>

         )
        }

      </div>
      {
       !loading?(

      <div className="card p-3">
        <h5
          className="mb-5 text-center p-2"
          style={{ color: "white", backgroundColor: "var(--naranja" }}
        >
          {" "}
          Detalle Empresa de Transporte
        </h5>
        <form className="row g-3">
          <h6>Datos de la Empresa de Transporte</h6>
          <div className="col-md-4">
            <label htmlFor="inputEmail4" className="Form-label">
              Razón social
            </label>
            <input
              type="text"
              className="form-control"
              id="inputEmail4"
              name="businessName"
              value={bussine?.businessName}
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputPassword4" className="Form-label">
              CUIT
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              value={bussine?.cuit}
              name="cuit"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress2" className="Form-label">
              Correo Electrónico
            </label>
            <input
                  disabled
              value={bussine?.email}
              name="email"
              type="text"
              className="form-control"
              id="inputAddress2"
              placeholder=""
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputPassword4" className="Form-label">
              CVU
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              value={bussine?.cvu}
              name="cuit"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputPassword4" className="Form-label">
              Alias
            </label>
            <input
              type="text"
              className="form-control"
              id="inputPassword4"
              value={bussine?.label}
              name="cuit"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputAddress" className="Form-label">
              Teléfono
            </label>
            <input
                  disabled
              value={bussine?.businessPhoneNumber}
              name="businessPhoneNumber"
              type="number"
              min={0}
              className="form-control"
              id="inputAddress"
            />
          </div>
        
          <hr style={{ borderTop: "2px dotted #000" }} />
          <h6>Datos de ubicación</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Pais
            </label>
            <input
              type="text"
              name="country"
              className="form-control"
              value={bussine?.country}
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputState" className="Form-label">
              Provincia
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.province}
              name="department"
              disabled
            />
          
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Localidad
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.department}
              name="department"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputZip" className="Form-label">
              Dirección
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.address}
              name="address"
              disabled
            />
          </div>
          <div className="col-md-2">
            <label htmlFor="inputZip" className="Form-label">
              Código Postal
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.postalCode}
              name="postalCode"
              disabled
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Datos del Representante legal</h6>
         
         
         
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Nombres
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.name}
              name="name"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Apellidos
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.lastName}
              name="lastName"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              DNI
            </label>
            <input
              type="text"
              className="form-control"
              value={bussine?.dni}
              name="dni"
              disabled
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Teléfono
            </label>
            <input
              type="number"
              min={0}
              className="form-control"
              value={bussine?.phoneNumber}
              name="phoneNumber"
              disabled
            />
          </div>
          <hr style={{ borderTop: "2px dotted #000" }} />{" "}
          <h6>Declaración jurada</h6>
          <div className="col-md-4">
            <label htmlFor="inputCity" className="Form-label">
              Rubro
            </label>
            <select
              className="form-control"
              name="category"
              value={bussine?.category}
              disabled
            >
              <option value="" disabled>
                {" "}
                Seleccionar rubro
              </option>
              <option value="supermercado">Supermercado</option>
              <option value="viajes_turismo">Viajes y Turismo</option>
              <option value="indumentaria">Indumentaria</option>
              <option value="restaurante">Restaurante</option>
              <option value="automovil">Automovil</option>
              <option value="hogar">Hogar</option>
              <option value="servicios">Servicios</option>
              <option value="otro">Otro</option>
            </select>
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declara que los fondos y valores a aplicar provienen de actividades
            lícitas
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a1"
              disabled
              checked={bussine?.licitActivity}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
           
            </label>
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser Persona Expuesta Politicamente
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a2"
              disabled
              checked={bussine?.politicallyExposed}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
         
            </label>
          </div>
          <div className="col-6">
            <input
              type="text"
              name="reason"
              className=" form-control"
              placeholder="Motivo"
              disabled
              value={bussine?.reason}
             
            />
          </div>
          <label className="Form-check-label" htmlFor="gridCheck">
            Declaro ser sujeto obligado ante UIF
          </label>
          <div className="form-check form-switch ">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckDefault"
              name="a3"
              disabled
              checked={bussine?.subjectUIF}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckDefault"
            >
          
            </label>
          </div>
          <div className="col-6">
            <input
              type="text"
              className=" form-control"
              placeholder="Motivo"
              name="reasonUIF"
              disabled
              value={bussine?.reasonUIF}
             
            />
          </div>
         
        </form>
      <Modal
        isOpen={modalIsOpen}
        overlayClassName={""}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
      <IoCloseCircleOutline size={25} style={{color:"red", cursor:"pointer"}} onClick={closeModal} />
        <h3 className='text-center' >Retiro de dinero <GiReceiveMoney size={28} style={{color:"#24cd69"}}/></h3>
        <div> </div>
        <form className='p-3'>
          <label htmlFor="">CVU destino</label>
          <input className='form-control' value={form.cvuDest} name='cvuDest' onChange={(e)=>onChangeForm(e)}/>
          <label htmlFor="">Monto $</label >
          <input className='form-control' value={form.amount} name='amount' onChange={(e)=>onChangeForm(e)}/>
          <label htmlFor="">Concepto</label>
          <input className='form-control' value={form.concept} name='concept' onChange={(e)=>onChangeForm(e)}/>
         
          <button className='btn btn-secondary mt-3' onClick={RetiroDinero}>Aceptar</button>
        </form>
      </Modal>
      </div>
        ) : (
         <Spiner/>
        ) 
      }
    </div>
  );
};

const customStyles = {

    content: {
      top: '50%',
      width: "40%",
      left: '55%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px', 
      borderRadius: '10px', 
    },
   
 
};


export default BussinessDetail


