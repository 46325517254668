import './App.css';
import Home from './components/Consola usuarios/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
function App() {
 

  return (
    <div className="App">
       <Home/>
    </div>
  )
}

export default App;
