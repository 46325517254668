import React from "react";
import {
  search_User_detalle,
  userUpdateState,
  Update_user,
  search_asociado_detail,
  getAsociados,
  getTypesUser
} from "../../redux/actions";
import { useSelector, dispatch, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaUserAltSlash, FaUserCheck } from "react-icons/fa";
import Switch from "react-switch";
import Swal from "sweetalert2";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { IoArrowBackCircleOutline, IoClose } from "react-icons/io5";
import { Spiner } from "../Spiner"; 
const DetalleAsociado = ({ id, pagina, setPagina, closeModal,message }) => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.detalle_asociado?.[0]);
  const listBeneficios = useSelector((state) => state.listTypes);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  useEffect(() => {
    dispatch(getTypesUser());
    dispatch(search_asociado_detail({ _id: id }));
  // console.log(user)
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, [message]);

  const handleChangeState = async (cuit, checked) => {
    setChecked(checked);
    const newState = checked ? "Activo" : "Inactivo";
    const data = { cuit: cuit, state: newState };
    const response = await dispatch(userUpdateState(data));
    // console.log(data);
    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: "Ok",
        text: "Usuario actualizado correctamente",
      });
      dispatch(search_asociado_detail({ _id: id }));
    } else {
      Swal.fire({
        icon: "error",
        title: "error",
        text: response?.msg || "ups! ocurrió un error ",
      });
    }
  };

  const handleuserChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    //console.log(data);
  };
  useEffect(() => {
    //console.log(user?.idType)
    if (user) {
      setData({
        name: user?.name || "",
        lastName: user?.lastName || "",
        birthDate: user?.birthDate?.slice(0, 10) || "",
        departament: user?.department || "",
        idType: user?.idType || "",
        city: user?.city || "",
        dni: user?.dni ? parseInt(user?.dni) : "",
        cuit: user?.cuit ? parseInt(user?.cuit) : "",
        phoneNumber: user?.phoneNumber || "",
        address: user?.address || "",
        email: user?.email || "",
        postalCode: user?.postalCode || "",
        province: user?.province || "",
      });
      setChecked(user?.state || false);
    }
  }, [user]);

  const guardarCambios = async () => {
    try {
      if (data) {
        setData({
          ...data,
        });
        const response = await dispatch(
          Update_user(user?.dni, { ...data, cuit: parseInt(data?.cuit) })
        );
        if (response?.status === 200) {
          Swal.fire({
            text: "Datos actualizados con éxito",
            icon: "success",
          });
        
          // setTimeout(() => {
          //   navigate("/list/asociados");
          // }, 500);
        
          dispatch(getAsociados(1))
         
          await dispatch(search_asociado_detail({ _id: id }));
            
       
        } else {
          Swal.fire({
            text: response?.msg || "Ocurró un error",
            icon: "error",
          });
        }
      }
    } catch (error) {
      Swal.fire({
        text: error.message,
        icon: "error",
      });
    }
  };

  return (
    <div className="container mt-3">
      <div className="d-flex">
        <Link to={message ? "/list/asociados" : "/usuarios"} onClick={message && closeModal}>
          <IoArrowBackCircleOutline
            style={{ fontSize: "30px", color: "orange" }}
          />
        </Link>
        <h4 className="ms-4"> Datos del usuario asociado </h4>
      </div>
      {user && !loading ? (
        <div key={user?._id} className="card mb-3">
          <div className="card-body">
            <div className="row">
              {/* Primera grilla */}
              <div className="col-md-6">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item align-items-center">
                    <b>Nombre:</b>{" "}
                    <input
                      className="form-control "
                      type="text"
                      name="name"
                      value={data?.name || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>Apellido:</b>{" "}
                    <input
                      className="form-control"
                      type="text"
                      name="lastName"
                      value={data?.lastName || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center justify-content-between">
                    <b>DNI:</b>{" "}
                    <input
                      className="form-control"
                      type="text"
                      name="dni"
                      value={data?.dni || ""}
                      disabled
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>CUIT:</b>{" "}
                    <input
                      disabled
                      className="form-control"
                      type="text"
                      name="cuit"
                      value={data?.cuit || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>Fecha de nacimiento:</b>{" "}
                    <input
                      disabled
                      className="form-control"
                      type="date"
                      name="birthDate"
                      value={data?.birthDate || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>Número de teléfono: </b>
                    <input
                      className="form-control"
                      type="text"
                      name="phoneNumber"
                      value={data?.phoneNumber || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                </ul>
              </div>

              {/* Segunda grilla */}
              <div className="col-md-6">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item  align-items-center">
                    <b>Dirección: </b>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      value={data?.address || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item align-items-center">
                    <b>Localidad: </b>
                    <input
                      className="form-control"
                      type="text"
                      name="city"
                      value={data?.city || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>Correo:</b>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      value={data?.email || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item  align-items-center">
                    <b>Código Postal:</b>
                    <input
                      className="form-control"
                      type="text"
                      name="postalCode"
                      value={data?.postalCode || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item align-items-center">
                    <b>Provincia:</b>
                    <input
                      className="form-control"
                      type="text"
                      name="province"
                      value={data?.province || ""}
                      onChange={handleuserChange}
                    />
                  </li>
                  <li className="list-group-item align-items-center">
                              <b>Beneficio:</b>
                              <select
                                className="form-control"
                                name="idType"
                                value={data?.idType}
                                onChange={(e) =>
                                  setData({ ...data, idType: e.target.value })
                                }
                              >
                                {listBeneficios?.msg?.map((el, i) => (
                                  <option key={el._id} value={el._id}>
                                    {el?.name}
                                  </option>
                                ))}
                              </select>
                            </li>
                  <li className="list-group-item align-items-center">
                    <b>Estado:</b>
                    <div className="d-flex">
                      <p
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          marginRight: 20,
                        }}
                      >
                        {user?.state}
                      </p>
                      <Switch
                        onChange={(checked) =>
                          handleChangeState(user?.cuit, checked)
                        }
                        checked={user?.state === "Activo"}
                      />

                      <div></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-around">
            <button
              type="button"
              className="btn btn-outline-success m-2 "
              onClick={guardarCambios}
            >
              {" "}
              Guardar Cambios
            </button>
          </div>
        </div>
      ) : (
        <Spiner></Spiner>
      )}
    </div>
  );
};

export default DetalleAsociado;
