import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "../../styles/Users.module.css";
import Paginado from "../Consola usuarios/Paginado";
import { Spiner } from "../Spiner";
import { Link } from "react-router-dom";
import { FaUserMinus } from "react-icons/fa";
import { BiSearch } from "react-icons/bi";
import { IoMdBus } from "react-icons/io";
import { FaUserPlus } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";
import { IoReload } from "react-icons/io5";
import { getTaxsList, updateTax } from "../../redux/actions";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
const Taxs = () => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState("Commission");
  const [loading, setLoading] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [verPorPagina, setVerPorPagina] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);

  const getList = async () => {
    // setLoading(true)
    const response = await dispatch(getTaxsList({ type: selected }));
    if (response?.msg) {
      setLoading(false);
      setList(response.msg);
    } else {
      setList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
    setTimeout(() => {
      if (!list.length) {
        setShowNoDataMessage(true);
      }
    }, 2000);
  }, [dispatch, selected]);
  if (loading) {
    return <Spiner />;
  }
  const reload = () => {
    setSelected("Commission");
    setLoading(true);
    dispatch(getTaxsList({ type: selected }));
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };
  const updateTaxs = async (cuit, newState) => {
    try {
      const data = { cuit, state: newState };
      const response = await dispatch(updateTax(data));
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: `Se ${newState ? "habilitó" : "inhabilitó"}`,
        });
        // Actualizar el estado local de administradores después de la actualización
        await dispatch(getTaxsList()); // Actualizar la lista de administradores después de la actualización
      }
    } catch (error) {
      console.error("Error updating administrator state:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo actualizar el estado del administrador",
      });
    }
  };

  const maximo = Math.ceil(list?.length / verPorPagina);
  return (
    <div>
      <div className="container">
        <div>
          <div className="d-flex align-items-center">
            <IoReload
              title="Recargar"
              onClick={reload}
              style={{
                fontSize: "25px",
                color: "orange",
                fontWeight: "bold",
                cursor: "pointer",
                marginLeft: 18,
              }}
            />
            <h5 style={{ margin: "10px", marginLeft: "20px" }} className="">
              Admin. Impuestos
            </h5>
            <Link
              to={"/add/impuesto"}
              style={{
                textDecoration: "none",
                color: "black",
                marginLeft: "50%",
              }}
            >
              <IoIosAddCircleOutline
                style={{
                  fontSize: "22px",
                  color: "orange",
                  marginRight: "3px",
                }}
              />
              Agregar Impuesto/Comisión
            </Link>
          </div>
          <div className="d-flex  pb-3 pt-3 align-items-center ">
            <label htmlFor="" className="fs-6">
              {" "}
              Filtrar:{" "}
            </label>
            <select
              className="form-control "
              style={{ width: 220 }}
              onChange={(e) => setSelected(e.target.value)}
            >
              <option value="Commission">Comision</option>
              <option value="Tax">Impuesto</option>
            </select>
          </div>
        </div>
        <hr className={"dottedHr"}></hr>
        {list && list.length > 0 ? (
          <div id="#admin">
            <div className="row">
              <div className="d-flex justify-content-between">
                <p className="fs-6">Total: {list?.length}</p>
              </div>
            </div>
            <table className={`table `}>
              <thead className="table-info">
                <tr>
                  <th>Fecha de la operación</th>
                  <th>Nombre</th>
                  <th>Cargo%</th>
                  <th>Tipo</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {list
                  .slice(
                    (pagina - 1) * verPorPagina,
                    (pagina - 1) * verPorPagina + verPorPagina
                  )
                  .map((adm) => (
                    <tr key={adm?._id} style={{ textAlign: "left" }}>
                      <td>{adm?.dateOperation?.slice(0, 10)}</td>
                      <td>{adm?.name}</td>
                      <td>{adm?.balance}</td>
                      <td>
                        {adm?.type === "Commission" ? "Comisión" : "Impuesto"}
                      </td>
                      <td>{adm?.state ? "Activo" : "Inactivo"}</td>
                      <td>
                        <FaEdit
                          style={{
                            fontSize: 25,
                            color: "green",
                            marginLeft: 20,
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigate("/add/impuesto", {
                              state: { Modificar: true, info: adm },
                            })
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <Paginado data={maximo} pagina={pagina} setPagina={setPagina} />
          </div>
        ) : (
          showNoDataMessage && (
            <div className="alert alert-warning" role="alert">
              No se encontraron datos. <BiSearch style={{ fontSize: "30px" }} />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Taxs;
